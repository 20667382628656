//table action buttons
.top-right-btn {
    background-color: $cus-color-primary;
    height: 32px;
    color: $cus-color-white;
    padding: 5px 15px;
    border-radius: 25px;
    transition-delay: 0.3ms;

    &:hover {
        background-color: $cus-color-secondry;
        color: $cus-color-white;
    }
}

//Action btn
.action-btn-exten {
    display: inline-block;
    cursor: pointer;
    background-color: #eaeaee;
    width: 32px;
    height: 32px;
    margin-left: 5px;
    text-align: center;
    padding-top: 3px;
    border-radius: 50%;
    transition: 0.3ms;
}

.cus-action {
    ul {
        padding: 10px 5px 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin: 0;

        li {
            @extend .action-btn-exten;

            &.cus-view {
                background-color: rgba($view-color, 0.2);

                svg {
                    fill: $view-color;
                }

                &:hover {
                    background-color: rgba($view-color, 1);

                    svg {
                        fill: $cus-color-white;
                    }
                }
            }

            &.cus-edit {
                background-color: rgba($edit-color, 0.2);

                svg {
                    fill: $edit-color;
                }

                &:hover {
                    background-color: rgba($edit-color, 1);

                    svg {
                        fill: $cus-color-white;
                    }
                }
            }

            &.cus-delete {
                background-color: rgba($delete-color, 0.2);

                svg {
                    fill: $delete-color;
                }

                &:hover {
                    background-color: rgba($delete-color, 1);

                    svg {
                        fill: $cus-color-white;
                    }
                }
            }
        }
    }
}

//Breadcrumb
.breadcrumb {
    display: block;
    flex-wrap: inherit;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
    margin-bottom: 20px;

    .en-breadcrumb-inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h1 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 1.2rem;
            vertical-align: middle;
            font-weight: 700;
            line-height: 0;
        }

        ul {
            display: inline-block;
            vertical-align: middle;

            li {
                float: left;
                padding: 0;
                margin-right: 10px;

                &::after {
                    display: none;
                }

                &::before {
                    display: none;
                }

                a {
                    padding-left: 15px;
                    position: relative;
                    font-weight: 500;
                    color: $cus-color-primary;
                    transition: 0.3ms;

                    &:hover {
                        color: $cus-color-secondry;
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        background-color: $cus-color-secondry;
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        left: 0;
                        top: 8px;
                    }
                }
            }
        }
    }
}

//Status bar
.cus-order-status {
    text-align: right;
    padding-top: 0px;

    ul {
        list-style: none;
        margin: 0;

        li {
            position: relative;
            display: inline-block;
            padding: 0;
            transition-delay: 0.3ms;

            &::after {
                display: none;
            }

            &.active,
            &:hover {
                a {
                    color: $cus-color-primary;
                    border-color: $cus-color-primary;
                }

                svg {
                    fill: $cus-color-primary;
                }

                span {
                    background-color: rgba($cus-color-primary, 1);
                    color: $cus-color-white;
                }
            }

            span {
                position: absolute;
                background-color: rgba($cus-color-primary, 0.2);
                color: $black-color;
                display: inline-block;
                padding: 2px 5px 2px;
                right: 0;
                top: -18px;
                font-size: 12px;
                border-radius: 25px;
            }

            a {
                font-size: 14px;
                border-bottom: 2px solid transparent;
                margin-left: 25px;
                padding-bottom: 19px;
                font-weight: 600;
                color: $black-color;

                svg {
                    fill: $icon-mute-color;
                    vertical-align: bottom;
                }
            }

            &.en-status-pending {
                span {
                    background-color: rgba($cus-color-secondry, 0.2);
                }

                &.active,
                &:hover {
                    a {
                        color: $cus-color-secondry;
                        border-color: $cus-color-secondry;
                    }

                    svg {
                        fill: $cus-color-secondry;
                    }

                    span {
                        background-color: rgba($cus-color-secondry, 1);
                        color: $cus-color-white;
                    }
                }
            }

            &.en-status-processing {
                span {
                    background-color: rgba($view-color, 0.2);
                }

                &.active,
                &:hover {
                    a {
                        color: $view-color;
                        border-color: $view-color;
                    }

                    svg {
                        fill: $view-color;
                    }

                    span {
                        background-color: rgba($view-color, 1);
                        color: $cus-color-white;
                    }
                }
            }

            &.en-status-ready {
                span {
                    background-color: rgba($cus-color-primary, 0.2);
                }

                &.active,
                &:hover {
                    a {
                        color: $cus-color-primary;
                        border-color: $cus-color-primary;
                    }

                    svg {
                        fill: $cus-color-primary;
                    }

                    span {
                        background-color: rgba($cus-color-primary, 1);
                        color: $cus-color-white;
                    }
                }
            }

            &.en-status-completed {
                span {
                    background-color: rgba($edit-color, 0.2);
                }

                &.active,
                &:hover {
                    a {
                        color: $edit-color;
                        border-color: $edit-color;
                    }

                    svg {
                        fill: $edit-color;
                    }

                    span {
                        background-color: rgba($edit-color, 1);
                        color: $cus-color-white;
                    }
                }
            }

            &.en-status-cancelled {
                span {
                    background-color: rgba($delete-color, 0.2);
                }

                &.active,
                &:hover {
                    a {
                        color: $delete-color;
                        border-color: $delete-color;
                    }

                    svg {
                        fill: $delete-color;
                    }

                    span {
                        background-color: rgba($delete-color, 1);
                        color: $cus-color-white;
                    }
                }
            }

            &.en-status-receipts {
                span {
                    background-color: rgba($ask-color, 0.2);
                }

                &.active,
                &:hover {
                    a {
                        color: $ask-color;
                        border-color: $ask-color;
                    }

                    svg {
                        fill: $ask-color;
                    }

                    span {
                        background-color: rgba($ask-color, 1);
                        color: $cus-color-white;
                    }
                }
            }
        }
    }

    &.view-order-container {
        ul {
            li {
                margin-left: 15px;
                vertical-align: middle;

                &:hover {
                    svg {
                        fill: #0f343b;
                    }
                }

                h6 {
                    margin-bottom: 0;

                    a {
                        color: #0f343b;
                        margin-left: 0;
                        border: none;

                        &:hover {
                            color: $cus-color-primary;
                        }
                    }
                }
            }
        }
    }
}

// add product button
.bage-btn {
    background-color: rgba($ask-color, 0.2);
    color: $ask-color;
    border: none;
    padding: 5px 10px;
    border-radius: 25px;

    svg {
        fill: $ask-color;
    }

    &:hover {
        background-color: rgba($ask-color, 1);
        color: $ask-color;

        svg {
            fill: $ask-color;
        }
    }

    &.bage-add {
        background-color: rgba($edit-color, 0.2);
        color: $edit-color;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;

        svg {
            fill: $edit-color;
        }

        &:hover,
        &:focus {
            background-color: rgba($edit-color, 1);
            color: $cus-color-white;

            svg {
                fill: $cus-color-white;
            }
        }
    }

    &.bage-remove {
        background-color: rgba($delete-color, 0.2);
        color: $delete-color;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;

        svg {
            fill: $delete-color;
        }

        &:hover,
        &:focus {
            background-color: rgba($delete-color, 1);
            color: $cus-color-white;

            svg {
                fill: $cus-color-white;
            }
        }
    }
}

//en-grid btn
.en-grid {
    .btn {
        padding: 0.3rem 1rem;
    }

    .btn-outline-light {
        color: #212121;
        background-color: #dee2e6;
        border-color: #dee2e6;
    }
}

//btn-order-group
.btn-order-group {
    background-color: rgba($cus-color-primary, 1);
    color: $cus-color-white;
    padding: 0.4rem 1rem;
    transition-delay: 0.4ms;

    &:hover {
        background-color: rgba($cus-color-secondry, 1);
    }
}

.btn-order-group-sub {
    background-color: rgba($cus-color-secondry, 1);
    color: $cus-color-white;
    padding: 0.4rem 1rem;
    transition-delay: 0.4ms;

    &:hover {
        background-color: rgba($cus-color-primary, 1);
    }
}

.en-grid-list-container {
    .card {
        position: relative;

        .badge {
            background-color: rgba($cus-color-secondry, 1);
            color: $cus-color-white;
        }

        .en-edit-btn {
            @extend .action-btn-exten;
            background-color: rgba($edit-color, 1);
            padding: 0;
            width: 36px;
            height: 36px;

            svg {
                fill: $cus-color-white;
            }

            &:hover {
                background-color: rgba($cus-color-secondry, 1);

                svg {
                    fill: $cus-color-white;
                }
            }
        }
    }

    //list-grid
    // &.list-grid {}
    //list-horizontal
    &.list-horizontal {
        .list-item {
            .list-thumb {
                img {
                    height: 80px;
                    width: 100px;
                }
            }

            .item-title {
                width: 230px;
            }
        }
    }
}

//account-settings-wrapper
.account-settings-wrapper {
    .account-setting-header {
        background-color: $cus-color-primary;
        border-radius: 10px 10px 0 0;
        height: 150px;
        overflow: hidden;

        img {
            background-position: left top;
            background-size: cover;
        }
    }

    .en-avatar-section {
        background-color: $bg-light-ask;

        .avatar {
            position: relative;
            border: 3px solid $cus-color-white;
            background-color: $cus-color-white;
            display: inline-block;
            border-radius: 50%;
            margin-top: -70px;
            margin-left: 15px;

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }

            .en-avatar-edit {
                position: absolute;
                right: 0;
                z-index: 1;
                top: -15px;

                input {
                    display: none;

                    +label {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        margin-bottom: 0;
                        border-radius: 100%;
                        background: $cus-color-white;
                        text-align: center;
                        padding-top: 6px;
                        box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
                        cursor: pointer;
                        font-weight: normal;
                        transition: all 0.2s ease-in-out;

                        svg {
                            fill: $cus-color-primary;
                        }

                        &:hover {
                            background: $cus-color-secondry;

                            svg {
                                fill: $cus-color-white;
                            }
                        }
                    }
                }
            }
        }
    }

    .en-profile-details {
        background-color: $bg-light-ask;
        padding: 15px;
        border-radius: 0 0 10px 10px;

        .en-cus-name {
            padding-left: 130px;
            padding-bottom: 40px;
            margin-top: -70px;

            h4 {
                font-size: 1.5rem;
                font-weight: 700;
            }

            h6 {
                color: $cus-color-primary;

                svg {
                    fill: $cus-color-primary;
                }
            }
        }

        .en-address-details {
            background-color: $cus-color-secondry;
            padding: 30px;
            border-radius: 10px;

            small,
            h6 {
                color: $cus-color-white;
            }

            svg {
                fill: $cus-color-white;
            }
        }

        .en-media-type {
            overflow-y: scroll;
            max-height: 150px;
        }
    }
}

// Dashboard customer
.en-customer-title {
    background-color: $cus-color-primary;
    padding: 15px 15px;
    color: $cus-color-white;
    font-weight: 600;
    font-size: 1rem;
}

.en-customer-details {
    ul {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            padding: 15px 10px 15px 65px;
            border-bottom: 1px dashed $border-line;

            .en-initial {
                position: absolute;
                background-color: $cus-color-primary;
                color: $cus-color-white;
                width: 42px;
                height: 42px;
                text-align: center;
                padding-top: 10px;
                border-radius: 50%;
                left: 10px;
                top: 8px;
            }

            .en-name-first-last {
                display: inline-block;
            }

            .en-badge {
                float: right;
                background-color: $cus-color-secondry;
                color: $cus-color-white;
                padding: 2px 15px;
                border-radius: 25px;
                font-size: 14px;
            }
        }
    }
}

// Add product
.en-product-group {
    padding: 15px;
    border: 1px dashed #ebebeb;
    border-radius: 10px;
    margin-bottom: 10px;
}

.en-add-new-item {
    padding: 10px 12px;
    background-color: $bg-light-ask;
    border-radius: 25px;

    small {
        color: $edit-color;
        font-weight: 200;
    }
}

// Action setting 
.en-social-media {
    position: relative;

    .en-media-type {
        .en-social-media-inner {
            display: inline-block;
            position: relative;
            background-color: rgba($cus-color-white, 0.2);
            padding: 5px 30px 5px 5px;
            border-radius: 25px;
            margin-right: 5px;
            margin-bottom: 2px;
            cursor: pointer;
            transition-delay: 0.4ms;

            &:hover {
                background-color: rgba($cus-color-white, 0.3);
            }

            .en-social-media-img {
                img {
                    width: 30px;
                    height: 30px;
                }

                span {
                    color: $cus-color-white;
                    font-weight: 600;
                    margin-left: 5px;
                }
            }

            .en-close-product {
                position: absolute;
                right: 8px;
                top: 6px;
                cursor: pointer;

                svg {
                    fill: $cus-color-white;
                }

                &:hover {
                    svg {
                        fill: $delete-color;
                    }
                }
            }
        }
    }

    .social-btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $cus-color-white;
        color: $black-color;
        padding: 4px 10px;
        font-size: 14px;
        font-weight: 600;

        svg {
            fill: $black-color !important;
        }

        &:hover {
            background-color: $cus-color-primary;
            color: $cus-color-white;

            svg {
                fill: $cus-color-white !important;
            }
        }
    }
}

textarea {
    background: #f4f5fc;
    border: none;
    padding: 0.375rem 0.75rem;
    width: 100%;
}

.modal-content {
    border: none;
    padding: 30px;
    border-radius: 15px;

    img {
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 15px;
    }
}

//Date Picker raja
dp-date-picker {
    width: 100%;

    &.dp-material {
        .dp-picker-input {
            box-sizing: border-box !important;
            width: 100% !important;
            font-size: 15px !important;
            color: $black;
            outline: none;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border: 1px solid #E6E7E9;
            border-radius: 8px;
            background-color: $white;
            background: transparent;
            height: calc(2.4em + 0.75rem + 2px) !important;
            padding: 0.5rem 1rem;
        }
    }

    .dp-material {
        &.dp-popup {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
            border-left: none;
            border-right: none;
            border-bottom: none;
            z-index: 9999;
            white-space: nowrap;
        }
    }

    dp-calendar-nav {
        &.dp-material {
            .dp-calendar-nav-container {
                height: 40px;
                border: none;
            }
        }
    }

    dp-day-calendar {
        &.dp-material {
            .dp-calendar-wrapper {
                border: none;
                padding: 15px;
            }

            .dp-calendar-weekday {
                color: $black;
                border: none;
                font-size: 11px;
            }

            .dp-current-day {
                border: none;
                background-color: $success-color;
            }
        }

        .dp-calendar-day {
            font-size: 12px;
        }
    }

    dp-day-time-calendar {
        &.dp-material {
            dp-time-select {
                border: none;
                border-top: 1px dashed #dcdcdc;
            }
        }
    }

    dp-month-calendar {
        &.dp-material {
            .dp-calendar-month {
                font-size: 12px;
                width: 45px;
                height: 45px;
                padding: 15px 0;
            }

            .dp-current-month {
                border: none !important;
                background-color: $success-color;
            }
        }
    }
}