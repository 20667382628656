.endev-active {
    background-color: darkcyan;
}

.endev {
    background-color: gainsboro;
}

.__PrivateStripeElement {
    width: 300px;
}

.ng-dropdown-panel {
    background-color: lightsteelblue
}





// .ng-select.ng-select-single .ng-select-container {
//     height: 36px;
// }

.ng-select .ng-select-container {
    color: #333;
}

.ng-select .ng-select-container {
    // background-color: #fff;
    border-radius: 4px;
    // border: 1px solid #ccc;
    min-height: 36px;
    align-items: center;
}

// .ng-select div,
// .ng-select input,
// .ng-select span {
//     box-sizing: border-box;
// }

.ng-select .ng-has-value .ng-placeholder {
    display: none;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #999;
}

// .ng-select.ng-select-opened>.ng-select-container {
//     background: #fff;
//     border-color: #b3b3b3 #ccc #d9d9d9;
// }

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ng-select .ng-select-container .ng-value-container {
    display: flex;
    flex: 1;
}

// .ng-select.ng-select-searchable .ng-select-container .ng-value-container .ng-input {
//     opacity: 1;
// }

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    // left: 0;
    padding-left: 10px;
    padding-right: 50px;
}

// .ng-select.ng-select-single .ng-select-container .ng-value-container,
// .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

.error {
    color: red;
}

label {
    span {
        color: red;
    }
}