@charset "UTF-8";
/*
 *
 *   GULL - Angular Bootstrap Admin Template
 *
 *
*/
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~ngx-toastr/toastr.css";
@import "~angular-calendar/css/angular-calendar.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~highlight.js/styles/github.css";
@import "~ladda/dist/ladda.min.css";
@import "../iconsmind/iconsmind.css";
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #263db5;
  --indigo: #6610f2;
  --purple: #0092b0;
  --pink: #e83e8c;
  --red: #d22346;
  --orange: #e97d23;
  --yellow: #FFC107;
  --green: #4CAF50;
  --teal: #20c997;
  --cyan: #9C27B0;
  --white: #fff;
  --gray: #27899d;
  --gray-dark: #1b5e6c;
  --blue: #263db5;
  --indigo: #6610f2;
  --purple: #0092b0;
  --pink: #e83e8c;
  --red: #d22346;
  --orange: #e97d23;
  --yellow: #FFC107;
  --green: #4CAF50;
  --teal: #20c997;
  --cyan: #9C27B0;
  --white: #fff;
  --gray: #27899d;
  --primary: #0092b0;
  --secondary: #1b5e6c;
  --success: #4CAF50;
  --info: #263db5;
  --warning: #FFC107;
  --danger: #d22346;
  --light: #bbb;
  --dark: #17505c;
  --gray-100: #f8f9fa;
  --gray-200: #eee;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #17505c;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0092b0;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #005364;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #27899d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #27899d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #27899d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #17505c;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #17505c;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e0e9;
}

.table-hover .table-primary:hover {
  background-color: #a5d8e3;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a5d8e3;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #bfd2d6;
}

.table-hover .table-secondary:hover {
  background-color: #afc7cc;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #afc7cc;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce;
}

.table-hover .table-success:hover {
  background-color: #bbe1bd;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bbe1bd;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c2c9ea;
}

.table-hover .table-info:hover {
  background-color: #afb8e3;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #afb8e3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2c1cb;
}

.table-hover .table-danger:hover {
  background-color: #eeacb9;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #eeacb9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #ececec;
}

.table-hover .table-light:hover {
  background-color: #dfdfdf;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #dfdfdf;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #beced1;
}

.table-hover .table-dark:hover {
  background-color: #afc3c6;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #afc3c6;
}

.table-gray-100,
.table-gray-100 > th,
.table-gray-100 > td {
  background-color: #fdfdfe;
}

.table-hover .table-gray-100:hover {
  background-color: #ececf6;
}
.table-hover .table-gray-100:hover > td,
.table-hover .table-gray-100:hover > th {
  background-color: #ececf6;
}

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #fafafa;
}

.table-hover .table-gray-200:hover {
  background-color: #ededed;
}
.table-hover .table-gray-200:hover > td,
.table-hover .table-gray-200:hover > th {
  background-color: #ededed;
}

.table-gray-300,
.table-gray-300 > th,
.table-gray-300 > td {
  background-color: #f6f7f8;
}

.table-hover .table-gray-300:hover {
  background-color: #e8eaed;
}
.table-hover .table-gray-300:hover > td,
.table-hover .table-gray-300:hover > th {
  background-color: #e8eaed;
}

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #f1f3f5;
}

.table-hover .table-gray-400:hover {
  background-color: #e2e6ea;
}
.table-hover .table-gray-400:hover > td,
.table-hover .table-gray-400:hover > th {
  background-color: #e2e6ea;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #17505c;
  border-color: #1f6b7a;
}
.table .thead-light th {
  color: #237b8d;
  background-color: #eee;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #17505c;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #1f6b7a;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #237b8d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #237b8d;
  background-color: #fff;
  border-color: #31dcff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.25);
}
.form-control::placeholder {
  color: #27899d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #eee;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #237b8d;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #17505c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #27899d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4CAF50;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #4CAF50;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4CAF50;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4CAF50;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #a3d7a5;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #6ec071;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4CAF50;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d22346;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(210, 35, 70, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d22346;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #d22346;
  box-shadow: 0 0 0 0.2rem rgba(210, 35, 70, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d22346;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d22346;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #eb899d;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e04866;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(210, 35, 70, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d22346;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(210, 35, 70, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover, .btn:focus {
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0092b0;
  border-color: #0092b0;
}
.btn-primary:hover {
  color: #fff;
  background-color: #00728a;
  border-color: #00687d;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0092b0;
  border-color: #0092b0;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00687d;
  border-color: #005d70;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #1b5e6c;
  border-color: #1b5e6c;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #13444e;
  border-color: #113b43;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 94, 108, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #1b5e6c;
  border-color: #1b5e6c;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #113b43;
  border-color: #0e3239;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 94, 108, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.btn-success:hover {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  border-color: #39833c;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #263db5;
  border-color: #263db5;
}
.btn-info:hover {
  color: #fff;
  background-color: #1f3295;
  border-color: #1d2f8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 61, 181, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #263db5;
  border-color: #263db5;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1d2f8b;
  border-color: #1b2b80;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 61, 181, 0.5);
}

.btn-warning {
  color: #17505c;
  background-color: #FFC107;
  border-color: #FFC107;
}
.btn-warning:hover {
  color: #17505c;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #17505c;
  background-color: #FFC107;
  border-color: #FFC107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #17505c;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #d22346;
  border-color: #d22346;
}
.btn-danger:hover {
  color: #fff;
  background-color: #b11e3b;
  border-color: #a61c37;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 35, 70, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #d22346;
  border-color: #d22346;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a61c37;
  border-color: #9b1a34;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 35, 70, 0.5);
}

.btn-light {
  color: #17505c;
  background-color: #bbb;
  border-color: #bbb;
}
.btn-light:hover {
  color: #17505c;
  background-color: #a8a8a8;
  border-color: #a2a2a2;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #17505c;
  background-color: #bbb;
  border-color: #bbb;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #17505c;
  background-color: #a2a2a2;
  border-color: #9b9b9b;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #17505c;
  border-color: #17505c;
}
.btn-dark:hover {
  color: #fff;
  background-color: #0f353d;
  border-color: #0d2d33;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 80, 92, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #17505c;
  border-color: #17505c;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d2d33;
  border-color: #0a2429;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 80, 92, 0.5);
}

.btn-gray-100 {
  color: #17505c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-gray-100:hover {
  color: #17505c;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-gray-100:focus, .btn-gray-100.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-gray-100.disabled, .btn-gray-100:disabled {
  color: #17505c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active, .show > .btn-gray-100.dropdown-toggle {
  color: #17505c;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-gray-200 {
  color: #17505c;
  background-color: #eee;
  border-color: #eee;
}
.btn-gray-200:hover {
  color: #17505c;
  background-color: #dbdbdb;
  border-color: #d5d5d5;
}
.btn-gray-200:focus, .btn-gray-200.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}
.btn-gray-200.disabled, .btn-gray-200:disabled {
  color: #17505c;
  background-color: #eee;
  border-color: #eee;
}
.btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active, .show > .btn-gray-200.dropdown-toggle {
  color: #17505c;
  background-color: #d5d5d5;
  border-color: #cecece;
}
.btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}

.btn-gray-300 {
  color: #17505c;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.btn-gray-300:hover {
  color: #17505c;
  background-color: #c8cfd6;
  border-color: #c1c9d0;
}
.btn-gray-300:focus, .btn-gray-300.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}
.btn-gray-300.disabled, .btn-gray-300:disabled {
  color: #17505c;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active, .show > .btn-gray-300.dropdown-toggle {
  color: #17505c;
  background-color: #c1c9d0;
  border-color: #bac2cb;
}
.btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-gray-400 {
  color: #17505c;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-gray-400:hover {
  color: #17505c;
  background-color: #b8c1ca;
  border-color: #b1bbc4;
}
.btn-gray-400:focus, .btn-gray-400.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-gray-400.disabled, .btn-gray-400:disabled {
  color: #17505c;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active, .show > .btn-gray-400.dropdown-toggle {
  color: #17505c;
  background-color: #b1bbc4;
  border-color: #aab4bf;
}
.btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-primary {
  color: #0092b0;
  background-color: transparent;
  background-image: none;
  border-color: #0092b0;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0092b0;
  border-color: #0092b0;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0092b0;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0092b0;
  border-color: #0092b0;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.5);
}

.btn-outline-secondary {
  color: #1b5e6c;
  background-color: transparent;
  background-image: none;
  border-color: #1b5e6c;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #1b5e6c;
  border-color: #1b5e6c;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 94, 108, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #1b5e6c;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1b5e6c;
  border-color: #1b5e6c;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 94, 108, 0.5);
}

.btn-outline-success {
  color: #4CAF50;
  background-color: transparent;
  background-image: none;
  border-color: #4CAF50;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #4CAF50;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}

.btn-outline-info {
  color: #263db5;
  background-color: transparent;
  background-image: none;
  border-color: #263db5;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #263db5;
  border-color: #263db5;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 61, 181, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #263db5;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #263db5;
  border-color: #263db5;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 61, 181, 0.5);
}

.btn-outline-warning {
  color: #FFC107;
  background-color: transparent;
  background-image: none;
  border-color: #FFC107;
}
.btn-outline-warning:hover {
  color: #17505c;
  background-color: #FFC107;
  border-color: #FFC107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FFC107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #17505c;
  background-color: #FFC107;
  border-color: #FFC107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #d22346;
  background-color: transparent;
  background-image: none;
  border-color: #d22346;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #d22346;
  border-color: #d22346;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 35, 70, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d22346;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d22346;
  border-color: #d22346;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 35, 70, 0.5);
}

.btn-outline-light {
  color: #bbb;
  background-color: transparent;
  background-image: none;
  border-color: #bbb;
}
.btn-outline-light:hover {
  color: #17505c;
  background-color: #bbb;
  border-color: #bbb;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #bbb;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #17505c;
  background-color: #bbb;
  border-color: #bbb;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.5);
}

.btn-outline-dark {
  color: #17505c;
  background-color: transparent;
  background-image: none;
  border-color: #17505c;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #17505c;
  border-color: #17505c;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 80, 92, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #17505c;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #17505c;
  border-color: #17505c;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 80, 92, 0.5);
}

.btn-outline-gray-100 {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-gray-100:hover {
  color: #17505c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-100.dropdown-toggle {
  color: #17505c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-gray-200 {
  color: #eee;
  background-color: transparent;
  background-image: none;
  border-color: #eee;
}
.btn-outline-gray-200:hover {
  color: #17505c;
  background-color: #eee;
  border-color: #eee;
}
.btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}
.btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
  color: #eee;
  background-color: transparent;
}
.btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-200.dropdown-toggle {
  color: #17505c;
  background-color: #eee;
  border-color: #eee;
}
.btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}

.btn-outline-gray-300 {
  color: #dee2e6;
  background-color: transparent;
  background-image: none;
  border-color: #dee2e6;
}
.btn-outline-gray-300:hover {
  color: #17505c;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}
.btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
  color: #dee2e6;
  background-color: transparent;
}
.btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-300.dropdown-toggle {
  color: #17505c;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-gray-400 {
  color: #ced4da;
  background-color: transparent;
  background-image: none;
  border-color: #ced4da;
}
.btn-outline-gray-400:hover {
  color: #17505c;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
  color: #ced4da;
  background-color: transparent;
}
.btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-400.dropdown-toggle {
  color: #17505c;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0092b0;
  background-color: transparent;
}
.btn-link:hover {
  color: #005364;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #27899d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #17505c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eee;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #17505c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #123e47;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0092b0;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #27899d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #27899d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #17505c;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #237b8d;
  text-align: center;
  white-space: nowrap;
  background-color: #eee;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #0092b0;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 146, 176, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #64e4ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #27899d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #eee;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0092b0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #0092b0;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 146, 176, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 146, 176, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0092b0;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 146, 176, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #237b8d;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%231b5e6c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #31dcff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 220, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #237b8d;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #27899d;
  background-color: #eee;
}
.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #31dcff;
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.25);
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #31dcff;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #eee;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #237b8d;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #237b8d;
  content: "Browse";
  background-color: #eee;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 146, 176, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 146, 176, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 146, 176, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0092b0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #64e4ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0092b0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #64e4ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0092b0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #64e4ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #27899d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #eee #eee #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #27899d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #237b8d;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0092b0;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
.card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
.card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
.card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
.card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
.card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
.card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eee;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #27899d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #27899d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0092b0;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #005364;
  text-decoration: none;
  background-color: #eee;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0092b0;
  border-color: #0092b0;
}
.page-item.disabled .page-link {
  color: #27899d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #0092b0;
}
.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #00687d;
}

.badge-secondary {
  color: #fff;
  background-color: #1b5e6c;
}
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #113b43;
}

.badge-success {
  color: #fff;
  background-color: #4CAF50;
}
.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #3d8b40;
}

.badge-info {
  color: #fff;
  background-color: #263db5;
}
.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2f8b;
}

.badge-warning {
  color: #17505c;
  background-color: #FFC107;
}
.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #17505c;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #d22346;
}
.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #a61c37;
}

.badge-light {
  color: #17505c;
  background-color: #bbb;
}
.badge-light[href]:hover, .badge-light[href]:focus {
  color: #17505c;
  text-decoration: none;
  background-color: #a2a2a2;
}

.badge-dark {
  color: #fff;
  background-color: #17505c;
}
.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0d2d33;
}

.badge-gray-100 {
  color: #17505c;
  background-color: #f8f9fa;
}
.badge-gray-100[href]:hover, .badge-gray-100[href]:focus {
  color: #17505c;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-gray-200 {
  color: #17505c;
  background-color: #eee;
}
.badge-gray-200[href]:hover, .badge-gray-200[href]:focus {
  color: #17505c;
  text-decoration: none;
  background-color: #d5d5d5;
}

.badge-gray-300 {
  color: #17505c;
  background-color: #dee2e6;
}
.badge-gray-300[href]:hover, .badge-gray-300[href]:focus {
  color: #17505c;
  text-decoration: none;
  background-color: #c1c9d0;
}

.badge-gray-400 {
  color: #17505c;
  background-color: #ced4da;
}
.badge-gray-400[href]:hover, .badge-gray-400[href]:focus {
  color: #17505c;
  text-decoration: none;
  background-color: #b1bbc4;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eee;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004c5c;
  background-color: #cce9ef;
  border-color: #b8e0e9;
}
.alert-primary hr {
  border-top-color: #a5d8e3;
}
.alert-primary .alert-link {
  color: #002229;
}

.alert-secondary {
  color: #0e3138;
  background-color: #d1dfe2;
  border-color: #bfd2d6;
}
.alert-secondary hr {
  border-top-color: #afc7cc;
}
.alert-secondary .alert-link {
  color: #040d0f;
}

.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce;
}
.alert-success hr {
  border-top-color: #bbe1bd;
}
.alert-success .alert-link {
  color: #18381a;
}

.alert-info {
  color: #14205e;
  background-color: #d4d8f0;
  border-color: #c2c9ea;
}
.alert-info hr {
  border-top-color: #afb8e3;
}
.alert-info .alert-link {
  color: #0b1234;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #6d1224;
  background-color: #f6d3da;
  border-color: #f2c1cb;
}
.alert-danger hr {
  border-top-color: #eeacb9;
}
.alert-danger .alert-link {
  color: #410b16;
}

.alert-light {
  color: #616161;
  background-color: #f1f1f1;
  border-color: #ececec;
}
.alert-light hr {
  border-top-color: #dfdfdf;
}
.alert-light .alert-link {
  color: #484848;
}

.alert-dark {
  color: #0c2a30;
  background-color: #d1dcde;
  border-color: #beced1;
}
.alert-dark hr {
  border-top-color: #afc3c6;
}
.alert-dark .alert-link {
  color: #020607;
}

.alert-gray-100 {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-gray-100 hr {
  border-top-color: #ececf6;
}
.alert-gray-100 .alert-link {
  color: #686868;
}

.alert-gray-200 {
  color: #7c7c7c;
  background-color: #fcfcfc;
  border-color: #fafafa;
}
.alert-gray-200 hr {
  border-top-color: #ededed;
}
.alert-gray-200 .alert-link {
  color: #636363;
}

.alert-gray-300 {
  color: #737678;
  background-color: #f8f9fa;
  border-color: #f6f7f8;
}
.alert-gray-300 hr {
  border-top-color: #e8eaed;
}
.alert-gray-300 .alert-link {
  color: #5a5c5e;
}

.alert-gray-400 {
  color: #6b6e71;
  background-color: #f5f6f8;
  border-color: #f1f3f5;
}
.alert-gray-400 hr {
  border-top-color: #e2e6ea;
}
.alert-gray-400 .alert-link {
  color: #525557;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #eee;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0092b0;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #237b8d;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #237b8d;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #17505c;
  background-color: #eee;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #27899d;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0092b0;
  border-color: #0092b0;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004c5c;
  background-color: #b8e0e9;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004c5c;
  background-color: #a5d8e3;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004c5c;
  border-color: #004c5c;
}

.list-group-item-secondary {
  color: #0e3138;
  background-color: #bfd2d6;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #0e3138;
  background-color: #afc7cc;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #0e3138;
  border-color: #0e3138;
}

.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #285b2a;
  background-color: #bbe1bd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #285b2a;
  border-color: #285b2a;
}

.list-group-item-info {
  color: #14205e;
  background-color: #c2c9ea;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #14205e;
  background-color: #afb8e3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #14205e;
  border-color: #14205e;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #6d1224;
  background-color: #f2c1cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #6d1224;
  background-color: #eeacb9;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #6d1224;
  border-color: #6d1224;
}

.list-group-item-light {
  color: #616161;
  background-color: #ececec;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #616161;
  background-color: #dfdfdf;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #616161;
  border-color: #616161;
}

.list-group-item-dark {
  color: #0c2a30;
  background-color: #beced1;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0c2a30;
  background-color: #afc3c6;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0c2a30;
  border-color: #0c2a30;
}

.list-group-item-gray-100 {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-gray-100.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-gray-200 {
  color: #7c7c7c;
  background-color: #fafafa;
}
.list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
  color: #7c7c7c;
  background-color: #ededed;
}
.list-group-item-gray-200.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}

.list-group-item-gray-300 {
  color: #737678;
  background-color: #f6f7f8;
}
.list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
  color: #737678;
  background-color: #e8eaed;
}
.list-group-item-gray-300.list-group-item-action.active {
  color: #fff;
  background-color: #737678;
  border-color: #737678;
}

.list-group-item-gray-400 {
  color: #6b6e71;
  background-color: #f1f3f5;
}
.list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
  color: #6b6e71;
  background-color: #e2e6ea;
}
.list-group-item-gray-400.list-group-item-action.active {
  color: #fff;
  background-color: #6b6e71;
  border-color: #6b6e71;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: black;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: black;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: black;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #eee;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: black;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: black;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: black;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: black;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: black;
  border-radius: 0.25rem;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #27899d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow, .bs-popover-auto[x-placement^=top] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow, .bs-popover-auto[x-placement^=right] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^=bottom] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow, .bs-popover-auto[x-placement^=left] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #17505c;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
.active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
.active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0092b0 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00687d !important;
}

.bg-secondary {
  background-color: #1b5e6c !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #113b43 !important;
}

.bg-success {
  background-color: #4CAF50 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important;
}

.bg-info {
  background-color: #263db5 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1d2f8b !important;
}

.bg-warning {
  background-color: #FFC107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #d22346 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a61c37 !important;
}

.bg-light {
  background-color: #bbb !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #a2a2a2 !important;
}

.bg-dark {
  background-color: #17505c !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0d2d33 !important;
}

.bg-gray-100 {
  background-color: #f8f9fa !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dae0e5 !important;
}

.bg-gray-200 {
  background-color: #eee !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #d5d5d5 !important;
}

.bg-gray-300 {
  background-color: #dee2e6 !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c1c9d0 !important;
}

.bg-gray-400 {
  background-color: #ced4da !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b1bbc4 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0092b0 !important;
}

.border-secondary {
  border-color: #1b5e6c !important;
}

.border-success {
  border-color: #4CAF50 !important;
}

.border-info {
  border-color: #263db5 !important;
}

.border-warning {
  border-color: #FFC107 !important;
}

.border-danger {
  border-color: #d22346 !important;
}

.border-light {
  border-color: #bbb !important;
}

.border-dark {
  border-color: #17505c !important;
}

.border-gray-100 {
  border-color: #f8f9fa !important;
}

.border-gray-200 {
  border-color: #eee !important;
}

.border-gray-300 {
  border-color: #dee2e6 !important;
}

.border-gray-400 {
  border-color: #ced4da !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0092b0 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #00687d !important;
}

.text-secondary {
  color: #1b5e6c !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #113b43 !important;
}

.text-success {
  color: #4CAF50 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #3d8b40 !important;
}

.text-info {
  color: #263db5 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #1d2f8b !important;
}

.text-warning {
  color: #FFC107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #d22346 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a61c37 !important;
}

.text-light {
  color: #bbb !important;
}

a.text-light:hover, a.text-light:focus {
  color: #a2a2a2 !important;
}

.text-dark {
  color: #17505c !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0d2d33 !important;
}

.text-gray-100 {
  color: #f8f9fa !important;
}

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #dae0e5 !important;
}

.text-gray-200 {
  color: #eee !important;
}

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #d5d5d5 !important;
}

.text-gray-300 {
  color: #dee2e6 !important;
}

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #c1c9d0 !important;
}

.text-gray-400 {
  color: #ced4da !important;
}

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #b1bbc4 !important;
}

.text-body {
  color: #17505c !important;
}

.text-muted {
  color: #27899d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #bbb;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid black;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
[dir=rtl] .text-left {
  text-align: right !important;
}
[dir=rtl] .text-right {
  text-align: left !important;
}
@media (min-width: 576px) {
  [dir=rtl] .text-sm-left {
    text-align: right !important;
  }
  [dir=rtl] .text-sm-right {
    text-align: left !important;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .text-md-left {
    text-align: right !important;
  }
  [dir=rtl] .text-md-right {
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .text-lg-left {
    text-align: right !important;
  }
  [dir=rtl] .text-lg-right {
    text-align: left !important;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .text-xl-left {
    text-align: right !important;
  }
  [dir=rtl] .text-xl-right {
    text-align: left !important;
  }
}
[dir=rtl] .float-left {
  float: right !important;
}
[dir=rtl] .float-right {
  float: left !important;
}
@media (min-width: 576px) {
  [dir=rtl] .float-sm-left {
    float: right !important;
  }
  [dir=rtl] .float-sm-right {
    float: left !important;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .float-md-left {
    float: right !important;
  }
  [dir=rtl] .float-md-right {
    float: left !important;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .float-lg-left {
    float: right !important;
  }
  [dir=rtl] .float-lg-right {
    float: left !important;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .float-xl-left {
    float: right !important;
  }
  [dir=rtl] .float-xl-right {
    float: left !important;
  }
}
[dir=rtl] .mr-0,
[dir=rtl] .mx-0 {
  margin-left: 0 !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-0,
[dir=rtl] .mx-0 {
  margin-right: 0 !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-1,
[dir=rtl] .mx-1 {
  margin-left: 0.25rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-1,
[dir=rtl] .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-2,
[dir=rtl] .mx-2 {
  margin-left: 0.5rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-2,
[dir=rtl] .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-3,
[dir=rtl] .mx-3 {
  margin-left: 1rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-3,
[dir=rtl] .mx-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-4,
[dir=rtl] .mx-4 {
  margin-left: 1.5rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-4,
[dir=rtl] .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-5,
[dir=rtl] .mx-5 {
  margin-left: 3rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-5,
[dir=rtl] .mx-5 {
  margin-right: 3rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-0,
[dir=rtl] .px-0 {
  padding-left: 0 !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-0,
[dir=rtl] .px-0 {
  padding-right: 0 !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-1,
[dir=rtl] .px-1 {
  padding-left: 0.25rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-1,
[dir=rtl] .px-1 {
  padding-right: 0.25rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-2,
[dir=rtl] .px-2 {
  padding-left: 0.5rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-2,
[dir=rtl] .px-2 {
  padding-right: 0.5rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-3,
[dir=rtl] .px-3 {
  padding-left: 1rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-3,
[dir=rtl] .px-3 {
  padding-right: 1rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-4,
[dir=rtl] .px-4 {
  padding-left: 1.5rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-4,
[dir=rtl] .px-4 {
  padding-right: 1.5rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-5,
[dir=rtl] .px-5 {
  padding-left: 3rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-5,
[dir=rtl] .px-5 {
  padding-right: 3rem !important;
  margin-left: unset !important;
}
@media (min-width: 576px) {
  [dir=rtl] .mr-sm-0,
[dir=rtl] .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-0,
[dir=rtl] .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-1,
[dir=rtl] .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-1,
[dir=rtl] .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-2,
[dir=rtl] .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-2,
[dir=rtl] .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-3,
[dir=rtl] .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-3,
[dir=rtl] .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-4,
[dir=rtl] .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-4,
[dir=rtl] .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-5,
[dir=rtl] .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-5,
[dir=rtl] .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-0,
[dir=rtl] .px-sm-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-0,
[dir=rtl] .px-sm-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-1,
[dir=rtl] .px-sm-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-1,
[dir=rtl] .px-sm-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-2,
[dir=rtl] .px-sm-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-2,
[dir=rtl] .px-sm-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-3,
[dir=rtl] .px-sm-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-3,
[dir=rtl] .px-sm-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-4,
[dir=rtl] .px-sm-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-4,
[dir=rtl] .px-sm-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-5,
[dir=rtl] .px-sm-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-5,
[dir=rtl] .px-sm-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .mr-md-0,
[dir=rtl] .mx-md-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-0,
[dir=rtl] .mx-md-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-1,
[dir=rtl] .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-1,
[dir=rtl] .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-2,
[dir=rtl] .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-2,
[dir=rtl] .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-3,
[dir=rtl] .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-3,
[dir=rtl] .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-4,
[dir=rtl] .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-4,
[dir=rtl] .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-5,
[dir=rtl] .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-5,
[dir=rtl] .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-0,
[dir=rtl] .px-md-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-0,
[dir=rtl] .px-md-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-1,
[dir=rtl] .px-md-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-1,
[dir=rtl] .px-md-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-2,
[dir=rtl] .px-md-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-2,
[dir=rtl] .px-md-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-3,
[dir=rtl] .px-md-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-3,
[dir=rtl] .px-md-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-4,
[dir=rtl] .px-md-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-4,
[dir=rtl] .px-md-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-5,
[dir=rtl] .px-md-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-5,
[dir=rtl] .px-md-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .mr-lg-0,
[dir=rtl] .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-0,
[dir=rtl] .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-1,
[dir=rtl] .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-1,
[dir=rtl] .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-2,
[dir=rtl] .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-2,
[dir=rtl] .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-3,
[dir=rtl] .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-3,
[dir=rtl] .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-4,
[dir=rtl] .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-4,
[dir=rtl] .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-5,
[dir=rtl] .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-5,
[dir=rtl] .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-0,
[dir=rtl] .px-lg-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-0,
[dir=rtl] .px-lg-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-1,
[dir=rtl] .px-lg-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-1,
[dir=rtl] .px-lg-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-2,
[dir=rtl] .px-lg-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-2,
[dir=rtl] .px-lg-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-3,
[dir=rtl] .px-lg-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-3,
[dir=rtl] .px-lg-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-4,
[dir=rtl] .px-lg-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-4,
[dir=rtl] .px-lg-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-5,
[dir=rtl] .px-lg-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-5,
[dir=rtl] .px-lg-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .mr-xl-0,
[dir=rtl] .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-0,
[dir=rtl] .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-1,
[dir=rtl] .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-1,
[dir=rtl] .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-2,
[dir=rtl] .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-2,
[dir=rtl] .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-3,
[dir=rtl] .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-3,
[dir=rtl] .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-4,
[dir=rtl] .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-4,
[dir=rtl] .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-5,
[dir=rtl] .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-5,
[dir=rtl] .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-0,
[dir=rtl] .px-xl-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-0,
[dir=rtl] .px-xl-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-1,
[dir=rtl] .px-xl-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-1,
[dir=rtl] .px-xl-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-2,
[dir=rtl] .px-xl-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-2,
[dir=rtl] .px-xl-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-3,
[dir=rtl] .px-xl-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-3,
[dir=rtl] .px-xl-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-4,
[dir=rtl] .px-xl-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-4,
[dir=rtl] .px-xl-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-5,
[dir=rtl] .px-xl-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-5,
[dir=rtl] .px-xl-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
[dir=rtl] .input-group > .input-group-prepend > .btn,
[dir=rtl] .input-group > .input-group-prepend > .input-group-text,
[dir=rtl] .input-group > .input-group-append:not(:last-child) > .btn,
[dir=rtl] .input-group > .input-group-append:not(:last-child) > .input-group-text,
[dir=rtl] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
[dir=rtl] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[dir=rtl] .input-group > .input-group-append > .btn,
[dir=rtl] .input-group > .input-group-append > .input-group-text,
[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .btn,
[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
[dir=rtl] .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
[dir=rtl] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
[dir=rtl] .input-group > .form-control:not(:last-child),
[dir=rtl] .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[dir=rtl] .input-group > .form-control:not(:first-child),
[dir=rtl] .input-group > .custom-select:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
[dir=rtl] .btn-group > .btn-group:not(:last-child) > .btn,
[dir=rtl] .btn-group > .btn:not(:first-child),
[dir=rtl] .btn-group > .btn-group:not(:first-child) > .btn {
  border-radius: 0;
}

.sidebar-gradient-purple-indigo .sidebar-left {
  /* fallback/image non-cover color */
  background-color: #0092b0;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #0092b0), color-stop(100%, #33214b));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* Standard */
  background: linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.gradient-purple-indigo {
  /* fallback/image non-cover color */
  background-color: #0092b0;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #0092b0), color-stop(100%, #33214b));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* Standard */
  background: linear-gradient(-154deg, #0092b0 0%, #33214b 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.btn.gradient-purple-indigo:active, .btn.gradient-purple-indigo.active {
  /* fallback/image non-cover color */
  background-color: #0092b0;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-90deg, #0092b0 0%, #33214b 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -90deg, right top, color-stop(0%, #0092b0), color-stop(100%, #33214b));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-90deg, #0092b0 0%, #33214b 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-90deg, #0092b0 0%, #33214b 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-90deg, #0092b0 0%, #33214b 100%);
  /* Standard */
  background: linear-gradient(-90deg, #0092b0 0%, #33214b 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.sidebar-gradient-black-blue .sidebar-left {
  /* fallback/image non-cover color */
  background-color: #004e92;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #004e92), color-stop(100%, #000428));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Standard */
  background: linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.gradient-black-blue {
  /* fallback/image non-cover color */
  background-color: #004e92;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #004e92), color-stop(100%, #000428));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Standard */
  background: linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.btn.gradient-black-blue:active, .btn.gradient-black-blue.active {
  /* fallback/image non-cover color */
  background-color: #004e92;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -90deg, right top, color-stop(0%, #004e92), color-stop(100%, #000428));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* Standard */
  background: linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.sidebar-gradient-black-gray .sidebar-left {
  /* fallback/image non-cover color */
  background-color: #404040;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #404040), color-stop(100%, #000000));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Standard */
  background: linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.gradient-black-gray {
  /* fallback/image non-cover color */
  background-color: #404040;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #404040), color-stop(100%, #000000));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Standard */
  background: linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.btn.gradient-black-gray:active, .btn.gradient-black-gray.active {
  /* fallback/image non-cover color */
  background-color: #404040;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -90deg, right top, color-stop(0%, #404040), color-stop(100%, #000000));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* Standard */
  background: linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.sidebar-gradient-steel-gray .sidebar-left {
  /* fallback/image non-cover color */
  background-color: #616d86;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #616d86), color-stop(100%, #1F1C2C));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Standard */
  background: linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.gradient-steel-gray {
  /* fallback/image non-cover color */
  background-color: #616d86;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #616d86), color-stop(100%, #1F1C2C));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Standard */
  background: linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.btn.gradient-steel-gray:active, .btn.gradient-steel-gray.active {
  /* fallback/image non-cover color */
  background-color: #616d86;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -90deg, right top, color-stop(0%, #616d86), color-stop(100%, #1F1C2C));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* Standard */
  background: linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.sidebar-blue .sidebar-left {
  background: #003473;
}

.blue {
  background: #003473;
}

.sidebar-midnight-blue .sidebar-left {
  background: #0C0C3C;
}

.midnight-blue {
  background: #0C0C3C;
}

.sidebar-indigo .sidebar-left {
  background: #3f51b5;
}

.indigo {
  background: #3f51b5;
}

.sidebar-dark-purple .sidebar-left {
  background: #322740;
}

.dark-purple {
  background: #322740;
}

.sidebar-purple .sidebar-left {
  background: #0092b0;
}

.purple {
  background: #0092b0;
}

.sidebar-pink .sidebar-left {
  background: #CB3066;
}

.pink {
  background: #CB3066;
}

.sidebar-red .sidebar-left {
  background: #f44336;
}

.red {
  background: #f44336;
}

.sidebar-gray .sidebar-left {
  background: #2d2d33;
}

.gray {
  background: #2d2d33;
}

.sidebar-slate-gray .sidebar-left {
  background: #405365;
}

.slate-gray {
  background: #405365;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 2s infinite linear;
}

h1, h2, h3, h4, h5, h6, .card-title, .text-title {
  color: #0f343b;
}

.text-10 {
  font-size: 10px;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-17 {
  font-size: 17px;
}

.text-18 {
  font-size: 18px;
}

.text-19 {
  font-size: 19px;
}

.text-20 {
  font-size: 20px;
}

.text-21 {
  font-size: 21px;
}

.text-22 {
  font-size: 22px;
}

.text-23 {
  font-size: 23px;
}

.text-24 {
  font-size: 24px;
}

.text-25 {
  font-size: 25px;
}

.text-26 {
  font-size: 26px;
}

.text-27 {
  font-size: 27px;
}

.text-28 {
  font-size: 28px;
}

.text-29 {
  font-size: 29px;
}

.text-30 {
  font-size: 30px;
}

.text-31 {
  font-size: 31px;
}

.text-32 {
  font-size: 32px;
}

.text-33 {
  font-size: 33px;
}

.text-34 {
  font-size: 34px;
}

.text-35 {
  font-size: 35px;
}

.text-36 {
  font-size: 36px;
}

.text-37 {
  font-size: 37px;
}

.text-38 {
  font-size: 38px;
}

.text-39 {
  font-size: 39px;
}

.text-40 {
  font-size: 40px;
}

.text-41 {
  font-size: 41px;
}

.text-42 {
  font-size: 42px;
}

.text-43 {
  font-size: 43px;
}

.text-44 {
  font-size: 44px;
}

.text-45 {
  font-size: 45px;
}

.text-46 {
  font-size: 46px;
}

.text-47 {
  font-size: 47px;
}

.text-48 {
  font-size: 48px;
}

.text-49 {
  font-size: 49px;
}

.text-50 {
  font-size: 50px;
}

.text-51 {
  font-size: 51px;
}

.text-52 {
  font-size: 52px;
}

.text-53 {
  font-size: 53px;
}

.text-54 {
  font-size: 54px;
}

.text-55 {
  font-size: 55px;
}

.text-56 {
  font-size: 56px;
}

.text-57 {
  font-size: 57px;
}

.text-58 {
  font-size: 58px;
}

.text-59 {
  font-size: 59px;
}

.text-60 {
  font-size: 60px;
}

.text-61 {
  font-size: 61px;
}

.text-62 {
  font-size: 62px;
}

.text-63 {
  font-size: 63px;
}

.text-64 {
  font-size: 64px;
}

.text-65 {
  font-size: 65px;
}

.text-66 {
  font-size: 66px;
}

.text-67 {
  font-size: 67px;
}

.text-68 {
  font-size: 68px;
}

.text-69 {
  font-size: 69px;
}

.text-70 {
  font-size: 70px;
}

.text-71 {
  font-size: 71px;
}

.text-72 {
  font-size: 72px;
}

.text-73 {
  font-size: 73px;
}

.text-74 {
  font-size: 74px;
}

.text-75 {
  font-size: 75px;
}

.text-76 {
  font-size: 76px;
}

.text-77 {
  font-size: 77px;
}

.text-78 {
  font-size: 78px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-301 {
  font-weight: 301;
}

.font-weight-302 {
  font-weight: 302;
}

.font-weight-303 {
  font-weight: 303;
}

.font-weight-304 {
  font-weight: 304;
}

.font-weight-305 {
  font-weight: 305;
}

.font-weight-306 {
  font-weight: 306;
}

.font-weight-307 {
  font-weight: 307;
}

.font-weight-308 {
  font-weight: 308;
}

.font-weight-309 {
  font-weight: 309;
}

.font-weight-310 {
  font-weight: 310;
}

.font-weight-311 {
  font-weight: 311;
}

.font-weight-312 {
  font-weight: 312;
}

.font-weight-313 {
  font-weight: 313;
}

.font-weight-314 {
  font-weight: 314;
}

.font-weight-315 {
  font-weight: 315;
}

.font-weight-316 {
  font-weight: 316;
}

.font-weight-317 {
  font-weight: 317;
}

.font-weight-318 {
  font-weight: 318;
}

.font-weight-319 {
  font-weight: 319;
}

.font-weight-320 {
  font-weight: 320;
}

.font-weight-321 {
  font-weight: 321;
}

.font-weight-322 {
  font-weight: 322;
}

.font-weight-323 {
  font-weight: 323;
}

.font-weight-324 {
  font-weight: 324;
}

.font-weight-325 {
  font-weight: 325;
}

.font-weight-326 {
  font-weight: 326;
}

.font-weight-327 {
  font-weight: 327;
}

.font-weight-328 {
  font-weight: 328;
}

.font-weight-329 {
  font-weight: 329;
}

.font-weight-330 {
  font-weight: 330;
}

.font-weight-331 {
  font-weight: 331;
}

.font-weight-332 {
  font-weight: 332;
}

.font-weight-333 {
  font-weight: 333;
}

.font-weight-334 {
  font-weight: 334;
}

.font-weight-335 {
  font-weight: 335;
}

.font-weight-336 {
  font-weight: 336;
}

.font-weight-337 {
  font-weight: 337;
}

.font-weight-338 {
  font-weight: 338;
}

.font-weight-339 {
  font-weight: 339;
}

.font-weight-340 {
  font-weight: 340;
}

.font-weight-341 {
  font-weight: 341;
}

.font-weight-342 {
  font-weight: 342;
}

.font-weight-343 {
  font-weight: 343;
}

.font-weight-344 {
  font-weight: 344;
}

.font-weight-345 {
  font-weight: 345;
}

.font-weight-346 {
  font-weight: 346;
}

.font-weight-347 {
  font-weight: 347;
}

.font-weight-348 {
  font-weight: 348;
}

.font-weight-349 {
  font-weight: 349;
}

.font-weight-350 {
  font-weight: 350;
}

.font-weight-351 {
  font-weight: 351;
}

.font-weight-352 {
  font-weight: 352;
}

.font-weight-353 {
  font-weight: 353;
}

.font-weight-354 {
  font-weight: 354;
}

.font-weight-355 {
  font-weight: 355;
}

.font-weight-356 {
  font-weight: 356;
}

.font-weight-357 {
  font-weight: 357;
}

.font-weight-358 {
  font-weight: 358;
}

.font-weight-359 {
  font-weight: 359;
}

.font-weight-360 {
  font-weight: 360;
}

.font-weight-361 {
  font-weight: 361;
}

.font-weight-362 {
  font-weight: 362;
}

.font-weight-363 {
  font-weight: 363;
}

.font-weight-364 {
  font-weight: 364;
}

.font-weight-365 {
  font-weight: 365;
}

.font-weight-366 {
  font-weight: 366;
}

.font-weight-367 {
  font-weight: 367;
}

.font-weight-368 {
  font-weight: 368;
}

.font-weight-369 {
  font-weight: 369;
}

.font-weight-370 {
  font-weight: 370;
}

.font-weight-371 {
  font-weight: 371;
}

.font-weight-372 {
  font-weight: 372;
}

.font-weight-373 {
  font-weight: 373;
}

.font-weight-374 {
  font-weight: 374;
}

.font-weight-375 {
  font-weight: 375;
}

.font-weight-376 {
  font-weight: 376;
}

.font-weight-377 {
  font-weight: 377;
}

.font-weight-378 {
  font-weight: 378;
}

.font-weight-379 {
  font-weight: 379;
}

.font-weight-380 {
  font-weight: 380;
}

.font-weight-381 {
  font-weight: 381;
}

.font-weight-382 {
  font-weight: 382;
}

.font-weight-383 {
  font-weight: 383;
}

.font-weight-384 {
  font-weight: 384;
}

.font-weight-385 {
  font-weight: 385;
}

.font-weight-386 {
  font-weight: 386;
}

.font-weight-387 {
  font-weight: 387;
}

.font-weight-388 {
  font-weight: 388;
}

.font-weight-389 {
  font-weight: 389;
}

.font-weight-390 {
  font-weight: 390;
}

.font-weight-391 {
  font-weight: 391;
}

.font-weight-392 {
  font-weight: 392;
}

.font-weight-393 {
  font-weight: 393;
}

.font-weight-394 {
  font-weight: 394;
}

.font-weight-395 {
  font-weight: 395;
}

.font-weight-396 {
  font-weight: 396;
}

.font-weight-397 {
  font-weight: 397;
}

.font-weight-398 {
  font-weight: 398;
}

.font-weight-399 {
  font-weight: 399;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-401 {
  font-weight: 401;
}

.font-weight-402 {
  font-weight: 402;
}

.font-weight-403 {
  font-weight: 403;
}

.font-weight-404 {
  font-weight: 404;
}

.font-weight-405 {
  font-weight: 405;
}

.font-weight-406 {
  font-weight: 406;
}

.font-weight-407 {
  font-weight: 407;
}

.font-weight-408 {
  font-weight: 408;
}

.font-weight-409 {
  font-weight: 409;
}

.font-weight-410 {
  font-weight: 410;
}

.font-weight-411 {
  font-weight: 411;
}

.font-weight-412 {
  font-weight: 412;
}

.font-weight-413 {
  font-weight: 413;
}

.font-weight-414 {
  font-weight: 414;
}

.font-weight-415 {
  font-weight: 415;
}

.font-weight-416 {
  font-weight: 416;
}

.font-weight-417 {
  font-weight: 417;
}

.font-weight-418 {
  font-weight: 418;
}

.font-weight-419 {
  font-weight: 419;
}

.font-weight-420 {
  font-weight: 420;
}

.font-weight-421 {
  font-weight: 421;
}

.font-weight-422 {
  font-weight: 422;
}

.font-weight-423 {
  font-weight: 423;
}

.font-weight-424 {
  font-weight: 424;
}

.font-weight-425 {
  font-weight: 425;
}

.font-weight-426 {
  font-weight: 426;
}

.font-weight-427 {
  font-weight: 427;
}

.font-weight-428 {
  font-weight: 428;
}

.font-weight-429 {
  font-weight: 429;
}

.font-weight-430 {
  font-weight: 430;
}

.font-weight-431 {
  font-weight: 431;
}

.font-weight-432 {
  font-weight: 432;
}

.font-weight-433 {
  font-weight: 433;
}

.font-weight-434 {
  font-weight: 434;
}

.font-weight-435 {
  font-weight: 435;
}

.font-weight-436 {
  font-weight: 436;
}

.font-weight-437 {
  font-weight: 437;
}

.font-weight-438 {
  font-weight: 438;
}

.font-weight-439 {
  font-weight: 439;
}

.font-weight-440 {
  font-weight: 440;
}

.font-weight-441 {
  font-weight: 441;
}

.font-weight-442 {
  font-weight: 442;
}

.font-weight-443 {
  font-weight: 443;
}

.font-weight-444 {
  font-weight: 444;
}

.font-weight-445 {
  font-weight: 445;
}

.font-weight-446 {
  font-weight: 446;
}

.font-weight-447 {
  font-weight: 447;
}

.font-weight-448 {
  font-weight: 448;
}

.font-weight-449 {
  font-weight: 449;
}

.font-weight-450 {
  font-weight: 450;
}

.font-weight-451 {
  font-weight: 451;
}

.font-weight-452 {
  font-weight: 452;
}

.font-weight-453 {
  font-weight: 453;
}

.font-weight-454 {
  font-weight: 454;
}

.font-weight-455 {
  font-weight: 455;
}

.font-weight-456 {
  font-weight: 456;
}

.font-weight-457 {
  font-weight: 457;
}

.font-weight-458 {
  font-weight: 458;
}

.font-weight-459 {
  font-weight: 459;
}

.font-weight-460 {
  font-weight: 460;
}

.font-weight-461 {
  font-weight: 461;
}

.font-weight-462 {
  font-weight: 462;
}

.font-weight-463 {
  font-weight: 463;
}

.font-weight-464 {
  font-weight: 464;
}

.font-weight-465 {
  font-weight: 465;
}

.font-weight-466 {
  font-weight: 466;
}

.font-weight-467 {
  font-weight: 467;
}

.font-weight-468 {
  font-weight: 468;
}

.font-weight-469 {
  font-weight: 469;
}

.font-weight-470 {
  font-weight: 470;
}

.font-weight-471 {
  font-weight: 471;
}

.font-weight-472 {
  font-weight: 472;
}

.font-weight-473 {
  font-weight: 473;
}

.font-weight-474 {
  font-weight: 474;
}

.font-weight-475 {
  font-weight: 475;
}

.font-weight-476 {
  font-weight: 476;
}

.font-weight-477 {
  font-weight: 477;
}

.font-weight-478 {
  font-weight: 478;
}

.font-weight-479 {
  font-weight: 479;
}

.font-weight-480 {
  font-weight: 480;
}

.font-weight-481 {
  font-weight: 481;
}

.font-weight-482 {
  font-weight: 482;
}

.font-weight-483 {
  font-weight: 483;
}

.font-weight-484 {
  font-weight: 484;
}

.font-weight-485 {
  font-weight: 485;
}

.font-weight-486 {
  font-weight: 486;
}

.font-weight-487 {
  font-weight: 487;
}

.font-weight-488 {
  font-weight: 488;
}

.font-weight-489 {
  font-weight: 489;
}

.font-weight-490 {
  font-weight: 490;
}

.font-weight-491 {
  font-weight: 491;
}

.font-weight-492 {
  font-weight: 492;
}

.font-weight-493 {
  font-weight: 493;
}

.font-weight-494 {
  font-weight: 494;
}

.font-weight-495 {
  font-weight: 495;
}

.font-weight-496 {
  font-weight: 496;
}

.font-weight-497 {
  font-weight: 497;
}

.font-weight-498 {
  font-weight: 498;
}

.font-weight-499 {
  font-weight: 499;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-501 {
  font-weight: 501;
}

.font-weight-502 {
  font-weight: 502;
}

.font-weight-503 {
  font-weight: 503;
}

.font-weight-504 {
  font-weight: 504;
}

.font-weight-505 {
  font-weight: 505;
}

.font-weight-506 {
  font-weight: 506;
}

.font-weight-507 {
  font-weight: 507;
}

.font-weight-508 {
  font-weight: 508;
}

.font-weight-509 {
  font-weight: 509;
}

.font-weight-510 {
  font-weight: 510;
}

.font-weight-511 {
  font-weight: 511;
}

.font-weight-512 {
  font-weight: 512;
}

.font-weight-513 {
  font-weight: 513;
}

.font-weight-514 {
  font-weight: 514;
}

.font-weight-515 {
  font-weight: 515;
}

.font-weight-516 {
  font-weight: 516;
}

.font-weight-517 {
  font-weight: 517;
}

.font-weight-518 {
  font-weight: 518;
}

.font-weight-519 {
  font-weight: 519;
}

.font-weight-520 {
  font-weight: 520;
}

.font-weight-521 {
  font-weight: 521;
}

.font-weight-522 {
  font-weight: 522;
}

.font-weight-523 {
  font-weight: 523;
}

.font-weight-524 {
  font-weight: 524;
}

.font-weight-525 {
  font-weight: 525;
}

.font-weight-526 {
  font-weight: 526;
}

.font-weight-527 {
  font-weight: 527;
}

.font-weight-528 {
  font-weight: 528;
}

.font-weight-529 {
  font-weight: 529;
}

.font-weight-530 {
  font-weight: 530;
}

.font-weight-531 {
  font-weight: 531;
}

.font-weight-532 {
  font-weight: 532;
}

.font-weight-533 {
  font-weight: 533;
}

.font-weight-534 {
  font-weight: 534;
}

.font-weight-535 {
  font-weight: 535;
}

.font-weight-536 {
  font-weight: 536;
}

.font-weight-537 {
  font-weight: 537;
}

.font-weight-538 {
  font-weight: 538;
}

.font-weight-539 {
  font-weight: 539;
}

.font-weight-540 {
  font-weight: 540;
}

.font-weight-541 {
  font-weight: 541;
}

.font-weight-542 {
  font-weight: 542;
}

.font-weight-543 {
  font-weight: 543;
}

.font-weight-544 {
  font-weight: 544;
}

.font-weight-545 {
  font-weight: 545;
}

.font-weight-546 {
  font-weight: 546;
}

.font-weight-547 {
  font-weight: 547;
}

.font-weight-548 {
  font-weight: 548;
}

.font-weight-549 {
  font-weight: 549;
}

.font-weight-550 {
  font-weight: 550;
}

.font-weight-551 {
  font-weight: 551;
}

.font-weight-552 {
  font-weight: 552;
}

.font-weight-553 {
  font-weight: 553;
}

.font-weight-554 {
  font-weight: 554;
}

.font-weight-555 {
  font-weight: 555;
}

.font-weight-556 {
  font-weight: 556;
}

.font-weight-557 {
  font-weight: 557;
}

.font-weight-558 {
  font-weight: 558;
}

.font-weight-559 {
  font-weight: 559;
}

.font-weight-560 {
  font-weight: 560;
}

.font-weight-561 {
  font-weight: 561;
}

.font-weight-562 {
  font-weight: 562;
}

.font-weight-563 {
  font-weight: 563;
}

.font-weight-564 {
  font-weight: 564;
}

.font-weight-565 {
  font-weight: 565;
}

.font-weight-566 {
  font-weight: 566;
}

.font-weight-567 {
  font-weight: 567;
}

.font-weight-568 {
  font-weight: 568;
}

.font-weight-569 {
  font-weight: 569;
}

.font-weight-570 {
  font-weight: 570;
}

.font-weight-571 {
  font-weight: 571;
}

.font-weight-572 {
  font-weight: 572;
}

.font-weight-573 {
  font-weight: 573;
}

.font-weight-574 {
  font-weight: 574;
}

.font-weight-575 {
  font-weight: 575;
}

.font-weight-576 {
  font-weight: 576;
}

.font-weight-577 {
  font-weight: 577;
}

.font-weight-578 {
  font-weight: 578;
}

.font-weight-579 {
  font-weight: 579;
}

.font-weight-580 {
  font-weight: 580;
}

.font-weight-581 {
  font-weight: 581;
}

.font-weight-582 {
  font-weight: 582;
}

.font-weight-583 {
  font-weight: 583;
}

.font-weight-584 {
  font-weight: 584;
}

.font-weight-585 {
  font-weight: 585;
}

.font-weight-586 {
  font-weight: 586;
}

.font-weight-587 {
  font-weight: 587;
}

.font-weight-588 {
  font-weight: 588;
}

.font-weight-589 {
  font-weight: 589;
}

.font-weight-590 {
  font-weight: 590;
}

.font-weight-591 {
  font-weight: 591;
}

.font-weight-592 {
  font-weight: 592;
}

.font-weight-593 {
  font-weight: 593;
}

.font-weight-594 {
  font-weight: 594;
}

.font-weight-595 {
  font-weight: 595;
}

.font-weight-596 {
  font-weight: 596;
}

.font-weight-597 {
  font-weight: 597;
}

.font-weight-598 {
  font-weight: 598;
}

.font-weight-599 {
  font-weight: 599;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-601 {
  font-weight: 601;
}

.font-weight-602 {
  font-weight: 602;
}

.font-weight-603 {
  font-weight: 603;
}

.font-weight-604 {
  font-weight: 604;
}

.font-weight-605 {
  font-weight: 605;
}

.font-weight-606 {
  font-weight: 606;
}

.font-weight-607 {
  font-weight: 607;
}

.font-weight-608 {
  font-weight: 608;
}

.font-weight-609 {
  font-weight: 609;
}

.font-weight-610 {
  font-weight: 610;
}

.font-weight-611 {
  font-weight: 611;
}

.font-weight-612 {
  font-weight: 612;
}

.font-weight-613 {
  font-weight: 613;
}

.font-weight-614 {
  font-weight: 614;
}

.font-weight-615 {
  font-weight: 615;
}

.font-weight-616 {
  font-weight: 616;
}

.font-weight-617 {
  font-weight: 617;
}

.font-weight-618 {
  font-weight: 618;
}

.font-weight-619 {
  font-weight: 619;
}

.font-weight-620 {
  font-weight: 620;
}

.font-weight-621 {
  font-weight: 621;
}

.font-weight-622 {
  font-weight: 622;
}

.font-weight-623 {
  font-weight: 623;
}

.font-weight-624 {
  font-weight: 624;
}

.font-weight-625 {
  font-weight: 625;
}

.font-weight-626 {
  font-weight: 626;
}

.font-weight-627 {
  font-weight: 627;
}

.font-weight-628 {
  font-weight: 628;
}

.font-weight-629 {
  font-weight: 629;
}

.font-weight-630 {
  font-weight: 630;
}

.font-weight-631 {
  font-weight: 631;
}

.font-weight-632 {
  font-weight: 632;
}

.font-weight-633 {
  font-weight: 633;
}

.font-weight-634 {
  font-weight: 634;
}

.font-weight-635 {
  font-weight: 635;
}

.font-weight-636 {
  font-weight: 636;
}

.font-weight-637 {
  font-weight: 637;
}

.font-weight-638 {
  font-weight: 638;
}

.font-weight-639 {
  font-weight: 639;
}

.font-weight-640 {
  font-weight: 640;
}

.font-weight-641 {
  font-weight: 641;
}

.font-weight-642 {
  font-weight: 642;
}

.font-weight-643 {
  font-weight: 643;
}

.font-weight-644 {
  font-weight: 644;
}

.font-weight-645 {
  font-weight: 645;
}

.font-weight-646 {
  font-weight: 646;
}

.font-weight-647 {
  font-weight: 647;
}

.font-weight-648 {
  font-weight: 648;
}

.font-weight-649 {
  font-weight: 649;
}

.font-weight-650 {
  font-weight: 650;
}

.font-weight-651 {
  font-weight: 651;
}

.font-weight-652 {
  font-weight: 652;
}

.font-weight-653 {
  font-weight: 653;
}

.font-weight-654 {
  font-weight: 654;
}

.font-weight-655 {
  font-weight: 655;
}

.font-weight-656 {
  font-weight: 656;
}

.font-weight-657 {
  font-weight: 657;
}

.font-weight-658 {
  font-weight: 658;
}

.font-weight-659 {
  font-weight: 659;
}

.font-weight-660 {
  font-weight: 660;
}

.font-weight-661 {
  font-weight: 661;
}

.font-weight-662 {
  font-weight: 662;
}

.font-weight-663 {
  font-weight: 663;
}

.font-weight-664 {
  font-weight: 664;
}

.font-weight-665 {
  font-weight: 665;
}

.font-weight-666 {
  font-weight: 666;
}

.font-weight-667 {
  font-weight: 667;
}

.font-weight-668 {
  font-weight: 668;
}

.font-weight-669 {
  font-weight: 669;
}

.font-weight-670 {
  font-weight: 670;
}

.font-weight-671 {
  font-weight: 671;
}

.font-weight-672 {
  font-weight: 672;
}

.font-weight-673 {
  font-weight: 673;
}

.font-weight-674 {
  font-weight: 674;
}

.font-weight-675 {
  font-weight: 675;
}

.font-weight-676 {
  font-weight: 676;
}

.font-weight-677 {
  font-weight: 677;
}

.font-weight-678 {
  font-weight: 678;
}

.font-weight-679 {
  font-weight: 679;
}

.font-weight-680 {
  font-weight: 680;
}

.font-weight-681 {
  font-weight: 681;
}

.font-weight-682 {
  font-weight: 682;
}

.font-weight-683 {
  font-weight: 683;
}

.font-weight-684 {
  font-weight: 684;
}

.font-weight-685 {
  font-weight: 685;
}

.font-weight-686 {
  font-weight: 686;
}

.font-weight-687 {
  font-weight: 687;
}

.font-weight-688 {
  font-weight: 688;
}

.font-weight-689 {
  font-weight: 689;
}

.font-weight-690 {
  font-weight: 690;
}

.font-weight-691 {
  font-weight: 691;
}

.font-weight-692 {
  font-weight: 692;
}

.font-weight-693 {
  font-weight: 693;
}

.font-weight-694 {
  font-weight: 694;
}

.font-weight-695 {
  font-weight: 695;
}

.font-weight-696 {
  font-weight: 696;
}

.font-weight-697 {
  font-weight: 697;
}

.font-weight-698 {
  font-weight: 698;
}

.font-weight-699 {
  font-weight: 699;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-701 {
  font-weight: 701;
}

.font-weight-702 {
  font-weight: 702;
}

.font-weight-703 {
  font-weight: 703;
}

.font-weight-704 {
  font-weight: 704;
}

.font-weight-705 {
  font-weight: 705;
}

.font-weight-706 {
  font-weight: 706;
}

.font-weight-707 {
  font-weight: 707;
}

.font-weight-708 {
  font-weight: 708;
}

.font-weight-709 {
  font-weight: 709;
}

.font-weight-710 {
  font-weight: 710;
}

.font-weight-711 {
  font-weight: 711;
}

.font-weight-712 {
  font-weight: 712;
}

.font-weight-713 {
  font-weight: 713;
}

.font-weight-714 {
  font-weight: 714;
}

.font-weight-715 {
  font-weight: 715;
}

.font-weight-716 {
  font-weight: 716;
}

.font-weight-717 {
  font-weight: 717;
}

.font-weight-718 {
  font-weight: 718;
}

.font-weight-719 {
  font-weight: 719;
}

.font-weight-720 {
  font-weight: 720;
}

.font-weight-721 {
  font-weight: 721;
}

.font-weight-722 {
  font-weight: 722;
}

.font-weight-723 {
  font-weight: 723;
}

.font-weight-724 {
  font-weight: 724;
}

.font-weight-725 {
  font-weight: 725;
}

.font-weight-726 {
  font-weight: 726;
}

.font-weight-727 {
  font-weight: 727;
}

.font-weight-728 {
  font-weight: 728;
}

.font-weight-729 {
  font-weight: 729;
}

.font-weight-730 {
  font-weight: 730;
}

.font-weight-731 {
  font-weight: 731;
}

.font-weight-732 {
  font-weight: 732;
}

.font-weight-733 {
  font-weight: 733;
}

.font-weight-734 {
  font-weight: 734;
}

.font-weight-735 {
  font-weight: 735;
}

.font-weight-736 {
  font-weight: 736;
}

.font-weight-737 {
  font-weight: 737;
}

.font-weight-738 {
  font-weight: 738;
}

.font-weight-739 {
  font-weight: 739;
}

.font-weight-740 {
  font-weight: 740;
}

.font-weight-741 {
  font-weight: 741;
}

.font-weight-742 {
  font-weight: 742;
}

.font-weight-743 {
  font-weight: 743;
}

.font-weight-744 {
  font-weight: 744;
}

.font-weight-745 {
  font-weight: 745;
}

.font-weight-746 {
  font-weight: 746;
}

.font-weight-747 {
  font-weight: 747;
}

.font-weight-748 {
  font-weight: 748;
}

.font-weight-749 {
  font-weight: 749;
}

.font-weight-750 {
  font-weight: 750;
}

.font-weight-751 {
  font-weight: 751;
}

.font-weight-752 {
  font-weight: 752;
}

.font-weight-753 {
  font-weight: 753;
}

.font-weight-754 {
  font-weight: 754;
}

.font-weight-755 {
  font-weight: 755;
}

.font-weight-756 {
  font-weight: 756;
}

.font-weight-757 {
  font-weight: 757;
}

.font-weight-758 {
  font-weight: 758;
}

.font-weight-759 {
  font-weight: 759;
}

.font-weight-760 {
  font-weight: 760;
}

.font-weight-761 {
  font-weight: 761;
}

.font-weight-762 {
  font-weight: 762;
}

.font-weight-763 {
  font-weight: 763;
}

.font-weight-764 {
  font-weight: 764;
}

.font-weight-765 {
  font-weight: 765;
}

.font-weight-766 {
  font-weight: 766;
}

.font-weight-767 {
  font-weight: 767;
}

.font-weight-768 {
  font-weight: 768;
}

.font-weight-769 {
  font-weight: 769;
}

.font-weight-770 {
  font-weight: 770;
}

.font-weight-771 {
  font-weight: 771;
}

.font-weight-772 {
  font-weight: 772;
}

.font-weight-773 {
  font-weight: 773;
}

.font-weight-774 {
  font-weight: 774;
}

.font-weight-775 {
  font-weight: 775;
}

.font-weight-776 {
  font-weight: 776;
}

.font-weight-777 {
  font-weight: 777;
}

.font-weight-778 {
  font-weight: 778;
}

.font-weight-779 {
  font-weight: 779;
}

.font-weight-780 {
  font-weight: 780;
}

.font-weight-781 {
  font-weight: 781;
}

.font-weight-782 {
  font-weight: 782;
}

.font-weight-783 {
  font-weight: 783;
}

.font-weight-784 {
  font-weight: 784;
}

.font-weight-785 {
  font-weight: 785;
}

.font-weight-786 {
  font-weight: 786;
}

.font-weight-787 {
  font-weight: 787;
}

.font-weight-788 {
  font-weight: 788;
}

.font-weight-789 {
  font-weight: 789;
}

.font-weight-790 {
  font-weight: 790;
}

.font-weight-791 {
  font-weight: 791;
}

.font-weight-792 {
  font-weight: 792;
}

.font-weight-793 {
  font-weight: 793;
}

.font-weight-794 {
  font-weight: 794;
}

.font-weight-795 {
  font-weight: 795;
}

.font-weight-796 {
  font-weight: 796;
}

.font-weight-797 {
  font-weight: 797;
}

.font-weight-798 {
  font-weight: 798;
}

.font-weight-799 {
  font-weight: 799;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-801 {
  font-weight: 801;
}

.font-weight-802 {
  font-weight: 802;
}

.font-weight-803 {
  font-weight: 803;
}

.font-weight-804 {
  font-weight: 804;
}

.font-weight-805 {
  font-weight: 805;
}

.font-weight-806 {
  font-weight: 806;
}

.font-weight-807 {
  font-weight: 807;
}

.font-weight-808 {
  font-weight: 808;
}

.font-weight-809 {
  font-weight: 809;
}

.font-weight-810 {
  font-weight: 810;
}

.font-weight-811 {
  font-weight: 811;
}

.font-weight-812 {
  font-weight: 812;
}

.font-weight-813 {
  font-weight: 813;
}

.font-weight-814 {
  font-weight: 814;
}

.font-weight-815 {
  font-weight: 815;
}

.font-weight-816 {
  font-weight: 816;
}

.font-weight-817 {
  font-weight: 817;
}

.font-weight-818 {
  font-weight: 818;
}

.font-weight-819 {
  font-weight: 819;
}

.font-weight-820 {
  font-weight: 820;
}

.font-weight-821 {
  font-weight: 821;
}

.font-weight-822 {
  font-weight: 822;
}

.font-weight-823 {
  font-weight: 823;
}

.font-weight-824 {
  font-weight: 824;
}

.font-weight-825 {
  font-weight: 825;
}

.font-weight-826 {
  font-weight: 826;
}

.font-weight-827 {
  font-weight: 827;
}

.font-weight-828 {
  font-weight: 828;
}

.font-weight-829 {
  font-weight: 829;
}

.font-weight-830 {
  font-weight: 830;
}

.font-weight-831 {
  font-weight: 831;
}

.font-weight-832 {
  font-weight: 832;
}

.font-weight-833 {
  font-weight: 833;
}

.font-weight-834 {
  font-weight: 834;
}

.font-weight-835 {
  font-weight: 835;
}

.font-weight-836 {
  font-weight: 836;
}

.font-weight-837 {
  font-weight: 837;
}

.font-weight-838 {
  font-weight: 838;
}

.font-weight-839 {
  font-weight: 839;
}

.font-weight-840 {
  font-weight: 840;
}

.font-weight-841 {
  font-weight: 841;
}

.font-weight-842 {
  font-weight: 842;
}

.font-weight-843 {
  font-weight: 843;
}

.font-weight-844 {
  font-weight: 844;
}

.font-weight-845 {
  font-weight: 845;
}

.font-weight-846 {
  font-weight: 846;
}

.font-weight-847 {
  font-weight: 847;
}

.font-weight-848 {
  font-weight: 848;
}

.font-weight-849 {
  font-weight: 849;
}

.font-weight-850 {
  font-weight: 850;
}

.font-weight-851 {
  font-weight: 851;
}

.font-weight-852 {
  font-weight: 852;
}

.font-weight-853 {
  font-weight: 853;
}

.font-weight-854 {
  font-weight: 854;
}

.font-weight-855 {
  font-weight: 855;
}

.font-weight-856 {
  font-weight: 856;
}

.font-weight-857 {
  font-weight: 857;
}

.font-weight-858 {
  font-weight: 858;
}

.font-weight-859 {
  font-weight: 859;
}

.font-weight-860 {
  font-weight: 860;
}

.font-weight-861 {
  font-weight: 861;
}

.font-weight-862 {
  font-weight: 862;
}

.font-weight-863 {
  font-weight: 863;
}

.font-weight-864 {
  font-weight: 864;
}

.font-weight-865 {
  font-weight: 865;
}

.font-weight-866 {
  font-weight: 866;
}

.font-weight-867 {
  font-weight: 867;
}

.font-weight-868 {
  font-weight: 868;
}

.font-weight-869 {
  font-weight: 869;
}

.font-weight-870 {
  font-weight: 870;
}

.font-weight-871 {
  font-weight: 871;
}

.font-weight-872 {
  font-weight: 872;
}

.font-weight-873 {
  font-weight: 873;
}

.font-weight-874 {
  font-weight: 874;
}

.font-weight-875 {
  font-weight: 875;
}

.font-weight-876 {
  font-weight: 876;
}

.font-weight-877 {
  font-weight: 877;
}

.font-weight-878 {
  font-weight: 878;
}

.font-weight-879 {
  font-weight: 879;
}

.font-weight-880 {
  font-weight: 880;
}

.font-weight-881 {
  font-weight: 881;
}

.font-weight-882 {
  font-weight: 882;
}

.font-weight-883 {
  font-weight: 883;
}

.font-weight-884 {
  font-weight: 884;
}

.font-weight-885 {
  font-weight: 885;
}

.font-weight-886 {
  font-weight: 886;
}

.font-weight-887 {
  font-weight: 887;
}

.font-weight-888 {
  font-weight: 888;
}

.font-weight-889 {
  font-weight: 889;
}

.font-weight-890 {
  font-weight: 890;
}

.font-weight-891 {
  font-weight: 891;
}

.font-weight-892 {
  font-weight: 892;
}

.font-weight-893 {
  font-weight: 893;
}

.font-weight-894 {
  font-weight: 894;
}

.font-weight-895 {
  font-weight: 895;
}

.font-weight-896 {
  font-weight: 896;
}

.font-weight-897 {
  font-weight: 897;
}

.font-weight-898 {
  font-weight: 898;
}

.font-weight-899 {
  font-weight: 899;
}

.font-weight-900 {
  font-weight: 900;
}

.text-small {
  font-size: 0.75rem;
}

.h-0px {
  height: 0px;
}

.h-100px {
  height: 100px;
}

.h-200px {
  height: 200px;
}

.h-300px {
  height: 300px;
}

.h-400px {
  height: 400px;
}

.h-500px {
  height: 500px;
}

.h-600px {
  height: 600px;
}

.h-700px {
  height: 700px;
}

.h-800px {
  height: 800px;
}

.h-900px {
  height: 900px;
}

.h-1000px {
  height: 1000px;
}

.h-100vh,
[h-100vh] {
  min-height: 100vh;
}

[w-0],
.w-0 {
  width: 0 !important;
}

[w-10],
.w-10 {
  width: 10 !important;
}

[w-20],
.w-20 {
  width: 20 !important;
}

[w-30],
.w-30 {
  width: 30 !important;
}

[w-40],
.w-40 {
  width: 40 !important;
}

[w-50],
.w-50 {
  width: 50 !important;
}

[w-60],
.w-60 {
  width: 60 !important;
}

[w-70],
.w-70 {
  width: 70 !important;
}

[w-80],
.w-80 {
  width: 80 !important;
}

[w-90],
.w-90 {
  width: 90 !important;
}

[w-100],
.w-100 {
  width: 100 !important;
}

@media (max-width: 767px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
.box-shadow-1 {
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.box-shadow-2 {
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.24), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.layout-sidebar-compact .main-header {
  position: absolute !important;
  width: 100%;
  left: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: transparent;
  z-index: 100;
  transition: all 0.24s ease-in-out;
}
.layout-sidebar-compact .main-header .logo {
  display: none;
}

.main-header {
  position: fixed;
  height: 80px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  z-index: 100;
}
.main-header .menu-toggle {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}
.main-header .menu-toggle div {
  width: 24px;
  height: 1px;
  background: #17505c;
  margin: 3px 0;
}
.main-header .search-bar {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 20px;
  position: relative;
  width: 230px;
  height: 40px;
}
.main-header .search-bar input {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: 0.8rem;
  line-height: 2;
  height: 100%;
  outline: initial !important;
  padding: 0.5rem 1rem;
  width: calc(100% - 32px);
}
.main-header .search-bar .search-icon {
  font-size: 18px;
  width: 24px;
  display: inline-block;
}
.main-header .logo {
  width: 76px;
}
.main-header .logo img {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
}
.main-header .header-icon {
  font-size: 19px;
  cursor: pointer;
  height: 36px;
  width: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  margin: 0 2px;
}
.main-header .header-icon:hover {
  background: #f8f9fa;
}
.main-header .header-icon.dropdown-toggle:after {
  display: none;
}
.main-header .header-part-right {
  display: flex;
  align-items: center;
}
.main-header .header-part-right .user {
  margin-right: 2rem;
}
.main-header .header-part-right .user img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.main-header .notification-dropdown {
  padding: 0;
  max-height: 260px;
  cursor: pointer;
}
.main-header .notification-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 0;
  height: 72px;
  border-bottom: 1px solid #dee2e6;
}
.main-header .notification-dropdown .dropdown-item .notification-icon {
  background: #eee;
  height: 100%;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-header .notification-dropdown .dropdown-item .notification-icon i {
  font-size: 18px;
}
.main-header .notification-dropdown .dropdown-item .notification-details {
  padding: 0.25rem 0.75rem;
}
.main-header .notification-dropdown .dropdown-item:active {
  color: inherit;
  background: inherit;
}

@media (max-width: 1024px) {
  .main-header .search-bar {
    width: 180px;
    display: none;
  }
  .main-header .menu-toggle {
    width: 24px;
    margin: 0 36px;
  }
  .main-header .header-part-right .user {
    margin-right: 1rem;
  }
}
@media (max-width: 767px) {
  .main-header .search-bar {
    display: none;
  }
  .main-header .menu-toggle {
    width: 24px;
    margin-right: 36px;
  }
}
@media (max-width: 576px) {
  .main-header {
    height: 70px;
    padding: 0 1.5rem;
  }
  .main-header .logo {
    width: 60px;
  }
  .main-header .menu-toggle {
    width: 60px;
  }
  .main-header .header-part-right .user {
    margin-right: 0;
    padding-right: 0;
  }

  .notification-dropdown {
    left: -180px !important;
  }
}
@media (max-width: 360px) {
  .main-header .menu-toggle {
    margin: 0;
  }
}
.layout-sidebar-compact.app-admin-wrap {
  width: 100%;
}
.layout-sidebar-compact.sidenav-open .main-content-wrap {
  width: calc(
        100% - 76px - 220px
      );
}
.layout-sidebar-compact .main-content-wrap {
  width: 100%;
  float: right;
  margin-top: 0;
  transition: all 0.24s ease-in-out;
  padding: 0 2rem;
  position: relative;
  min-height: calc(100vh - 80px);
  background: #fff;
}
.layout-sidebar-compact .main-content {
  margin-top: 104px;
}
.layout-sidebar-compact .sidebar-left-secondary,
.layout-sidebar-compact .sidebar-left {
  position: fixed;
  top: 0;
  height: 100vh;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  z-index: 101;
}
.layout-sidebar-compact .sidebar-left {
  left: calc(-76px - 20px);
  transition: all 0.24s ease-in-out;
}
.layout-sidebar-compact .sidebar-left.open {
  left: 0;
}
.layout-sidebar-compact .sidebar-left .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  border-bottom: 1px solid rgba(248, 249, 250, 0.05);
}
.layout-sidebar-compact .sidebar-left .logo img {
  width: 40px;
}
.layout-sidebar-compact .sidebar-left .navigation-left {
  list-style: none;
  text-align: center;
  width: 76px;
  height: 100%;
  margin: 0;
  padding: 0;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item {
  position: relative;
  display: block;
  width: 100%;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid rgba(248, 249, 250, 0.05);
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item:focus, .layout-sidebar-compact .sidebar-left .navigation-left .nav-item:active {
  outline: none;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item.lvl1 {
  text-align: center;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item.active {
  color: #fff;
  border-left: 2px solid #fff;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold {
  width: 100%;
  padding: 22px 0;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold:focus, .layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold:active {
  outline: none;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-icon,
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold .feather {
  font-size: 24px;
  height: 24px;
  width: 24px;
  display: block;
  margin: 0 auto;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-text {
  display: none;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold a {
  color: #fff;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item.active .triangle {
  display: none;
}
.layout-sidebar-compact.sidenav-open .sidebar-left-secondary {
  left: 76px;
}
.layout-sidebar-compact .sidebar-left-secondary {
  left: calc(-220px - 20px);
  width: 220px;
  padding: 0.75rem 0;
  transition: all 0.24s ease-in-out;
  background: #fff;
}
.layout-sidebar-compact .sidebar-left-secondary .sidebar-close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  padding: 16px;
}
.layout-sidebar-compact .sidebar-left-secondary header {
  padding: 0px 24px;
}
.layout-sidebar-compact .sidebar-left-secondary header .logo {
  padding: 10px 0;
  margin-bottom: 14px;
}
.layout-sidebar-compact .sidebar-left-secondary header .logo img {
  width: auto;
  height: 24px;
}
.layout-sidebar-compact .sidebar-left-secondary header h6 {
  font-size: 18px;
  margin-bottom: 4px;
  font-weight: 600;
}
.layout-sidebar-compact .sidebar-left-secondary header p {
  color: #27899d;
  margin-bottom: 12px;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav {
  list-style: none;
  padding: 0;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item {
  display: block;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a {
  color: #0f343b;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  padding: 12px 24px;
  transition: 0.15s all ease-in;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a:hover {
  background: #eee;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a.open {
  color: #0092b0;
  background: #eee;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  font-size: 18px;
  margin-right: 8px;
  vertical-align: middle;
  color: #27899d;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .item-name {
  vertical-align: middle;
  font-weight: 400;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .dd-arrow {
  margin-left: auto;
  font-size: 11px;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item .submenu {
  margin-left: 8px;
}
.layout-sidebar-compact .sidebar-left-secondary > .childNav {
  margin: 0;
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item.open > div > a > .dd-arrow {
  transform: rotate(90deg);
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item.open > div > .childNav {
  max-height: 1000px;
  overflow: visible;
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item > div > a > .dd-arrow {
  transition: all 0.4s ease-in-out;
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item > div > .childNav {
  max-height: 0;
  overflow: hidden;
  background: #fff;
  transition: all 0.4s ease-in-out;
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item > div > .childNav li.nav-item a {
  padding: 12px 12px 12px 50px;
}
.layout-sidebar-compact .sidebar-overlay {
  display: none !important;
}

@media (max-width: 767px) {
  .layout-sidebar-compact.sidenav-open .main-content-wrap {
    width: 100%;
  }
  .layout-sidebar-compact .sidebar-left-secondary .sidebar-close {
    display: block;
  }
}
@media (max-width: 576px) {
  .main-content-wrap {
    padding: 1.5rem;
  }
  .main-content-wrap.sidenav-open {
    width: 100%;
  }

  .main-content-wrap {
    margin-top: 70px;
  }

  .sidebar-left {
    left: calc(-90px - 20px);
  }
  .sidebar-left .navigation-left {
    width: 90px;
  }
  .sidebar-left .navigation-left .nav-item.active .triangle {
    border-width: 0 0 24px 24px;
  }
  .sidebar-left .navigation-left .nav-item .nav-item-hold {
    padding: 16px 0;
  }

  .sidebar-left-secondary {
    left: calc(-190px - 20px);
    width: 190px;
  }
  .sidebar-left-secondary.open {
    left: 90px;
  }

  .sidebar-overlay {
    height: calc(100vh - 70px);
  }
}
[dir=rtl] .layout-sidebar-compact .sidebar-left {
  left: auto !important;
  right: calc(-76px - 20px);
}
[dir=rtl] .layout-sidebar-compact .sidebar-left.open {
  left: auto !important;
  right: 0;
}
[dir=rtl] .layout-sidebar-compact.sidenav-open .sidebar-left-secondary {
  right: 76px;
}
[dir=rtl] .layout-sidebar-compact .sidebar-left-secondary {
  left: auto !important;
  right: calc(-220px - 20px);
}
[dir=rtl] .layout-sidebar-compact .sidebar-left-secondary header {
  text-align: right;
}
[dir=rtl] .layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .dd-arrow {
  margin-left: unset !important;
  margin-right: auto;
}
[dir=rtl] .layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  margin-left: 8px;
  margin-right: 0;
}
[dir=rtl] .layout-sidebar-compact .main-content-wrap {
  float: left;
}

.layout-sidebar-large .main-header {
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  z-index: 100;
}
.layout-sidebar-large .main-header .menu-toggle {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}
.layout-sidebar-large .main-header .menu-toggle div {
  width: 24px;
  height: 1px;
  background: #17505c;
  margin: 3px 0;
}
.layout-sidebar-large .main-header .search-bar {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 20px;
  position: relative;
  width: 230px;
  height: 40px;
}
.layout-sidebar-large .main-header .search-bar input {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: 0.8rem;
  line-height: 2;
  height: 100%;
  outline: initial !important;
  padding: 0.5rem 1rem;
  width: calc(100% - 32px);
}
.layout-sidebar-large .main-header .search-bar .search-icon {
  font-size: 18px;
  width: 24px;
  display: inline-block;
}
.layout-sidebar-large .main-header .logo {
  width: 120px;
}
.layout-sidebar-large .main-header .logo img {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
}
.layout-sidebar-large .main-header .header-icon {
  font-size: 19px;
  cursor: pointer;
  height: 36px;
  width: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  margin: 0 2px;
}
.layout-sidebar-large .main-header .header-icon:hover {
  background: #f8f9fa;
}
.layout-sidebar-large .main-header .header-icon.dropdown-toggle:after {
  display: none;
}
.layout-sidebar-large .main-header .header-part-right {
  display: flex;
  align-items: center;
}
.layout-sidebar-large .main-header .header-part-right .user {
  margin-right: 2rem;
}
.layout-sidebar-large .main-header .header-part-right .user img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.layout-sidebar-large .main-header .notification-dropdown {
  padding: 0;
  max-height: 260px;
  cursor: pointer;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 0;
  height: 72px;
  border-bottom: 1px solid #dee2e6;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item .notification-icon {
  background: #eee;
  height: 100%;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item .notification-icon i {
  font-size: 18px;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item .notification-details {
  padding: 0.25rem 0.75rem;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item:active {
  color: inherit;
  background: inherit;
}

@media (max-width: 991px) {
  .layout-sidebar-large .main-header .search-bar {
    width: 180px;
  }
  .layout-sidebar-large .main-header .menu-toggle {
    width: 24px;
    margin-right: 36px;
  }
}
@media (max-width: 780px) {
  .layout-sidebar-large .main-header .search-bar {
    display: none;
  }
}
@media (max-width: 767px) {
  .layout-sidebar-large .main-header .menu-toggle {
    width: 24px;
    margin-right: 36px;
  }
}
@media (max-width: 576px) {
  .layout-sidebar-large .main-header {
    height: 70px;
    padding: 0 1.5rem;
  }
  .layout-sidebar-large .main-header .logo {
    width: 60px;
  }
  .layout-sidebar-large .main-header .search-bar {
    display: none;
  }
  .layout-sidebar-large .main-header .menu-toggle {
    width: 60px;
  }
  .layout-sidebar-large .main-header .header-part-right .user {
    margin-right: 0;
    padding-right: 0;
  }
  .layout-sidebar-large .notification-dropdown {
    left: -180px !important;
  }
}
@media (max-width: 360px) {
  .layout-sidebar-large .main-header .menu-toggle {
    margin: 0;
  }
}
.app-admin-wrap {
  width: 100%;
}

.main-content-wrap {
  width: 100%;
  float: right;
  margin-top: 80px;
  transition: all 0.24s ease-in-out;
  padding: 2rem 2rem 0;
  position: relative;
  min-height: calc(100vh - 80px);
  background: #fff;
}
.main-content-wrap.sidenav-open {
  width: calc(100% - 120px);
}

.layout-sidebar-large .sidebar-left-secondary,
.layout-sidebar-large .sidebar-left {
  position: fixed;
  top: 80px;
  height: calc(100vh - 80px);
  background: #fff;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
}
.layout-sidebar-large .sidebar-left {
  left: calc(-120px - 20px);
  z-index: 90;
  transition: all 0.24s ease-in-out;
}
.layout-sidebar-large .sidebar-left.open {
  left: 0;
}
.layout-sidebar-large .sidebar-left .logo {
  display: none;
}
.layout-sidebar-large .sidebar-left .navigation-left {
  list-style: none;
  text-align: center;
  width: 120px;
  height: 100%;
  margin: 0;
  padding: 0;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item {
  position: relative;
  display: block;
  width: 100%;
  color: #0f343b;
  cursor: pointer;
  border-bottom: 1px solid #dee2e6;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item:focus, .layout-sidebar-large .sidebar-left .navigation-left .nav-item:active {
  outline: none;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item.lvl1 {
  text-align: center;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active {
  color: #0092b0;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold {
  width: 100%;
  padding: 26px 0;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold:focus, .layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold:active {
  outline: none;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-icon, .layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold .feather {
  font-size: 32px;
  height: 32px;
  width: 32px;
  display: block;
  margin: 0 auto 6px;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-text {
  font-size: 13px;
  display: block;
  font-weight: 400;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold a {
  color: #0f343b;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active .triangle {
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 0 0 30px 30px;
  border-color: transparent transparent #0092b0 transparent;
}
.layout-sidebar-large .sidebar-left-secondary {
  left: calc(-220px - 20px);
  z-index: 89;
  width: 220px;
  padding: 0.75rem 0;
  transition: all 0.24s ease-in-out;
  background: #fff;
}
.layout-sidebar-large .sidebar-left-secondary.open {
  left: 120px;
}
.layout-sidebar-large .sidebar-left-secondary header {
  display: none;
}
.layout-sidebar-large .sidebar-left-secondary .childNav {
  list-style: none;
  padding: 0;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item {
  display: block;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a {
  color: #0f343b;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  padding: 12px 24px;
  transition: 0.15s all ease-in;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a:hover {
  background: #eee;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a.open {
  color: #0092b0;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  font-size: 18px;
  margin-right: 8px;
  vertical-align: middle;
  color: #27899d;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .item-name {
  vertical-align: middle;
  font-weight: 400;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .dd-arrow {
  margin-left: auto;
  font-size: 11px;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item .submenu {
  margin-left: 8px;
}
.layout-sidebar-large .sidebar-left-secondary > .childNav {
  margin: 0;
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item.open > div > a > .dd-arrow {
  transform: rotate(90deg);
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item.open > div > .childNav {
  max-height: 1000px;
  overflow: visible;
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item > div > a > .dd-arrow {
  transition: all 0.4s ease-in-out;
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item > div > .childNav {
  max-height: 0;
  overflow: hidden;
  background: #fff;
  transition: all 0.4s ease-in-out;
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item > div > .childNav li.nav-item a {
  padding: 12px 12px 12px 50px;
}
.layout-sidebar-large .sidebar-overlay {
  display: none;
  position: fixed;
  width: calc(100% - 120px - 220px);
  height: calc(100vh - 80px);
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 101;
  cursor: w-resize;
}
.layout-sidebar-large .sidebar-overlay.open {
  display: block;
}

.module-loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
}
.module-loader .loader, .module-loader .spinner {
  position: fixed;
  top: 45%;
  left: calc(50% + 56px);
  z-index: inherit;
}

@media (max-width: 576px) {
  .main-content-wrap {
    padding: 1.5rem;
  }
  .main-content-wrap.sidenav-open {
    width: 100%;
  }

  .main-content-wrap {
    margin-top: 70px;
  }

  .layout-sidebar-large .sidebar-left-secondary,
.layout-sidebar-large .sidebar-left {
    top: 70px !important;
    height: calc(100vh - 70px) !important;
  }

  .sidebar-left {
    left: calc(-90px - 20px);
  }
  .sidebar-left .navigation-left {
    width: 90px;
  }
  .sidebar-left .navigation-left .nav-item.active .triangle {
    border-width: 0 0 24px 24px;
  }
  .sidebar-left .navigation-left .nav-item .nav-item-hold {
    padding: 16px 0;
  }

  .sidebar-left-secondary {
    left: calc(-190px - 20px);
    width: 190px;
  }
  .sidebar-left-secondary.open {
    left: 90px;
  }

  .sidebar-overlay {
    height: calc(100vh - 70px);
  }
}
[dir=rtl] .layout-sidebar-large .sidebar-left {
  left: auto !important;
  right: calc(-120px - 20px);
}
[dir=rtl] .layout-sidebar-large .sidebar-left.open {
  left: auto !important;
  right: 0;
}
[dir=rtl] .layout-sidebar-large .sidebar-left .navigation-left .nav-item .triangle {
  transform: rotate(90deg);
  right: auto;
  left: 0;
}
[dir=rtl] .layout-sidebar-large .sidebar-left-secondary {
  left: auto !important;
  right: calc(-220px - 20px);
}
[dir=rtl] .layout-sidebar-large .sidebar-left-secondary.open {
  left: auto !important;
  right: 120px;
}
[dir=rtl] .layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .dd-arrow {
  margin-left: unset !important;
  margin-right: auto;
}
[dir=rtl] .layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  margin-left: 8px;
  margin-right: 0;
}
[dir=rtl] .layout-sidebar-large .main-content-wrap {
  float: left;
}
[dir=rtl] .layout-sidebar-large .sidebar-overlay {
  right: auto !important;
  left: 0;
  cursor: e-resize;
}

.app-footer {
  margin-top: 2rem;
  background: #f8f9fa;
  padding: 1.25rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.app-footer .footer-bottom {
  width: 100%;
}
.app-footer .footer-bottom .logo {
  width: 3rem;
  height: auto;
  margin: 4px;
}

[dir=rtl] .notification-dropdown .dropdown-item .notification-details {
  text-align: right;
}
[dir=rtl] .main-header .user {
  margin-left: 2rem;
  margin-right: 0;
}

[role=tab] .btn {
  width: 100%;
  text-align: left;
}
[role=tab] .btn:hover, [role=tab] .btn:focus {
  text-decoration: none;
}

.accordion > .card {
  overflow: hidden;
}

.avatar-sm {
  width: 36px;
  height: 36px;
}

.avatar-md {
  width: 54px;
  height: 54px;
}

.avatar-lg {
  width: 80px;
  height: 80px;
}

.border-bottom-primary {
  border-bottom: 1px solid #0092b0;
}

.border-bottom-secondary {
  border-bottom: 1px solid #1b5e6c;
}

.border-bottom-success {
  border-bottom: 1px solid #4CAF50;
}

.border-bottom-info {
  border-bottom: 1px solid #263db5;
}

.border-bottom-warning {
  border-bottom: 1px solid #FFC107;
}

.border-bottom-danger {
  border-bottom: 1px solid #d22346;
}

.border-bottom-light {
  border-bottom: 1px solid #bbb;
}

.border-bottom-dark {
  border-bottom: 1px solid #17505c;
}

.border-bottom-gray-100 {
  border-bottom: 1px solid #f8f9fa;
}

.border-bottom-gray-200 {
  border-bottom: 1px solid #eee;
}

.border-bottom-gray-300 {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-gray-400 {
  border-bottom: 1px solid #ced4da;
}

.border-bottom-dotted-primary {
  border-bottom: 1px dotted #0092b0;
}

.border-bottom-dotted-secondary {
  border-bottom: 1px dotted #1b5e6c;
}

.border-bottom-dotted-success {
  border-bottom: 1px dotted #4CAF50;
}

.border-bottom-dotted-info {
  border-bottom: 1px dotted #263db5;
}

.border-bottom-dotted-warning {
  border-bottom: 1px dotted #FFC107;
}

.border-bottom-dotted-danger {
  border-bottom: 1px dotted #d22346;
}

.border-bottom-dotted-light {
  border-bottom: 1px dotted #bbb;
}

.border-bottom-dotted-dark {
  border-bottom: 1px dotted #17505c;
}

.border-bottom-dotted-gray-100 {
  border-bottom: 1px dotted #f8f9fa;
}

.border-bottom-dotted-gray-200 {
  border-bottom: 1px dotted #eee;
}

.border-bottom-dotted-gray-300 {
  border-bottom: 1px dotted #dee2e6;
}

.border-bottom-dotted-gray-400 {
  border-bottom: 1px dotted #ced4da;
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  border: 0;
}
.card.border-top {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50;
}

.card-header, .card-footer {
  border-color: rgba(0, 0, 0, 0.03);
}

.card-title {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.card-img-overlay * {
  position: relative;
  z-index: 1;
}
.card-img-overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.36);
  z-index: 0;
}
.card-img-overlay .separator {
  width: 35px;
  margin: auto;
}
.card-img-overlay .card-footer {
  position: absolute;
  bottom: 16px;
  left: 20px;
  border: 0;
  background: transparent;
}
.card-img-overlay .card-footer [class^=i-] {
  font-size: 0.875rem;
  vertical-align: text-bottom;
}

.card-icon .card-body {
  padding: 2rem 0.5rem;
}
.card-icon [class^=i-] {
  font-size: 32px;
}
.card-icon [class^=i-], .card-icon .lead {
  color: #0092b0;
}

.card-icon-big .card-body {
  padding: 2rem 0.5rem;
}
.card-icon-big [class^=i-] {
  font-size: 48px;
}
.card-icon-big [class^=i-] {
  color: rgba(0, 146, 176, 0.6);
}

.card-icon-bg {
  position: relative;
  z-index: 1;
}
.card-icon-bg .card-body {
  display: flex;
}
.card-icon-bg .card-body .content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 70px;
}
.card-icon-bg [class^=i-] {
  font-size: 4rem;
  color: rgba(187, 187, 187, 0.28);
}
.card-icon-bg .lead {
  line-height: 1;
}

.card-icon-bg-primary [class^=i-] {
  color: rgba(0, 146, 176, 0.28);
}

.card-icon-bg-secondary [class^=i-] {
  color: rgba(27, 94, 108, 0.28);
}

.card-icon-bg-success [class^=i-] {
  color: rgba(76, 175, 80, 0.28);
}

.card-icon-bg-info [class^=i-] {
  color: rgba(38, 61, 181, 0.28);
}

.card-icon-bg-warning [class^=i-] {
  color: rgba(255, 193, 7, 0.28);
}

.card-icon-bg-danger [class^=i-] {
  color: rgba(210, 35, 70, 0.28);
}

.card-icon-bg-light [class^=i-] {
  color: rgba(187, 187, 187, 0.28);
}

.card-icon-bg-dark [class^=i-] {
  color: rgba(23, 80, 92, 0.28);
}

.card-icon-bg-gray-100 [class^=i-] {
  color: rgba(248, 249, 250, 0.28);
}

.card-icon-bg-gray-200 [class^=i-] {
  color: rgba(238, 238, 238, 0.28);
}

.card-icon-bg-gray-300 [class^=i-] {
  color: rgba(222, 226, 230, 0.28);
}

.card-icon-bg-gray-400 [class^=i-] {
  color: rgba(206, 212, 218, 0.28);
}

.card-profile-1 .avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: auto;
  border-radius: 50%;
}

.card-ecommerce-1 .card-body [class^=i-] {
  display: block;
  font-size: 78px;
  color: #0092b0;
}

.card-ecommerce-2 .row {
  margin: 0;
}
.card-ecommerce-2 .card-action, .card-ecommerce-2 .col {
  padding: 1rem;
}
.card-ecommerce-2 .card-action {
  position: relative;
  display: flex;
  align-items: center;
}
.card-ecommerce-2 .card-action .icon {
  font-size: 22px;
  height: 24px;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  margin: 0 8px;
  cursor: pointer;
}
.card-ecommerce-2 .card-action:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background: rgba(23, 80, 92, 0.1);
}

.card-ecommerce-3 .card-img-left {
  height: 220px;
  object-fit: cover;
}

.card-socials-simple a {
  display: inline-block;
  padding: 4px;
}

.card-zoom-in {
  position: relative;
  background-color: white;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-zoom-in:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-zoom-in:hover {
  transform: scale(1.2);
}

.card-zoom-in:hover:after {
  opacity: 1;
}

.cal-header .cal-cell {
  color: #bbb;
}

.cal-day-badge {
  background-color: #d22346 !important;
}

.cal-month-view .cal-open-day-events {
  background-color: #eee;
  box-shadow: none;
  color: #17505c;
}

.cal-event-action i {
  vertical-align: middle;
}

.cal-month-view .cal-day-cell.cal-today {
  background: rgba(0, 146, 176, 0.2);
}

.breadcrumb {
  background: transparent;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: 0;
}
.breadcrumb h1 {
  font-size: 1.5rem;
  line-height: 1;
  padding-right: 0.5rem;
  margin: 0;
}
.breadcrumb ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.breadcrumb ul li {
  display: inline-block;
  position: relative;
  padding: 0 0.5rem;
  line-height: 1;
  vertical-align: bottom;
  color: #27899d;
}
.breadcrumb ul li:after {
  position: absolute;
  top: -1px;
  right: 0;
  content: "";
  height: 16px;
  width: 1px;
  background: #27899d;
  border-radius: 5px;
}
.breadcrumb ul li:last-child:after {
  display: none;
}
.breadcrumb ul li a {
  color: #0f343b;
}

[dir=rtl] .breadcrumb h1 {
  padding-right: 0;
  padding-left: 0.5rem;
}

html [type=button], .btn[type=button] {
  -webkit-appearance: none !important;
}

.btn.rounded, .btn.btn-rounded {
  border-radius: 40px;
}
.btn.btn-wide {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn-secondary {
  color: #17505c;
  background-color: #eee;
}

.btn-icon {
  width: 34px;
  height: 34px;
  padding: 0;
}
.btn-icon [class^=i-], .btn-icon .icon {
  vertical-align: middle;
  margin: 0 2px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.btn-icon-text [class^=i-], .btn-icon-text .icon {
  vertical-align: middle;
  margin: 0 2px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.btn-facebook {
  background: #3765c9;
  border-color: #3765c9;
}
.btn-facebook:hover {
  background: #315bb5;
  border-color: #315bb5;
}
.btn-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 101, 201, 0.5);
}

.btn-facebook {
  background: #ec412c;
  border-color: #ec412c;
}
.btn-facebook:hover {
  background: #e92c15;
  border-color: #e92c15;
}
.btn-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 65, 44, 0.5);
}

.btn-twitter {
  background: #039ff5;
  border-color: #039ff5;
}
.btn-twitter:hover {
  background: #038fdc;
  border-color: #038fdc;
}
.btn-twitter:focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 159, 245, 0.5);
}

.btn-outline-facebook {
  color: #3765c9;
  border-color: #3765c9;
  background: rgba(238, 238, 238, 0.6);
}
.btn-outline-facebook:hover {
  background: #315bb5;
  border-color: #315bb5;
}
.btn-outline-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 101, 201, 0.5);
}

.btn-outline-google {
  color: #ec412c;
  border-color: #ec412c;
  background: rgba(238, 238, 238, 0.6);
}
.btn-outline-google:hover {
  background: #e92c15;
  border-color: #e92c15;
}
.btn-outline-google:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 65, 44, 0.5);
}

.btn-outline-twitter {
  color: #039ff5;
  border-color: #039ff5;
  background: rgba(238, 238, 238, 0.6);
}
.btn-outline-twitter:hover {
  background: #038fdc;
  border-color: #038fdc;
}
.btn-outline-twitter:focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 159, 245, 0.5);
}

.btn-outline-email {
  background: rgba(238, 238, 238, 0.6);
}

.btn-spinner {
  width: 1em;
  height: 1em;
  background: transparent;
  border-radius: 50%;
  margin: 0 16px 0 0;
  border: 2px solid transparent;
}

.btn-checkbox .checkbox, .btn-checkbox .radio {
  display: inline;
}

.btn.btn-outline-light.btn-svg {
  border-color: #237b8d;
}
.btn.btn-outline-light.btn-svg.active, .btn.btn-outline-light.btn-svg:hover {
  background: #0092b0;
  border-color: #0092b0;
}
.btn.btn-outline-light.btn-svg.active svg, .btn.btn-outline-light.btn-svg:hover svg {
  fill: #ffffff;
}
.btn.btn-outline-light.btn-svg:focus {
  box-shadow: none !important;
}

.btn-primary .btn-spinner {
  animation: btn-glow-primary 1s ease infinite;
}

@keyframes btn-glow-primary {
  0% {
    box-shadow: 0 0 0 0.4em #00687d, 0 0 0 0.1em #00687d;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #00687d, 0 0 0 3.6em transparent;
  }
}
.btn-secondary .btn-spinner {
  animation: btn-glow-secondary 1s ease infinite;
}

@keyframes btn-glow-secondary {
  0% {
    box-shadow: 0 0 0 0.4em #113b43, 0 0 0 0.1em #113b43;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #113b43, 0 0 0 3.6em transparent;
  }
}
.btn-success .btn-spinner {
  animation: btn-glow-success 1s ease infinite;
}

@keyframes btn-glow-success {
  0% {
    box-shadow: 0 0 0 0.4em #3d8b40, 0 0 0 0.1em #3d8b40;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #3d8b40, 0 0 0 3.6em transparent;
  }
}
.btn-info .btn-spinner {
  animation: btn-glow-info 1s ease infinite;
}

@keyframes btn-glow-info {
  0% {
    box-shadow: 0 0 0 0.4em #1d2f8b, 0 0 0 0.1em #1d2f8b;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #1d2f8b, 0 0 0 3.6em transparent;
  }
}
.btn-warning .btn-spinner {
  animation: btn-glow-warning 1s ease infinite;
}

@keyframes btn-glow-warning {
  0% {
    box-shadow: 0 0 0 0.4em #d39e00, 0 0 0 0.1em #d39e00;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #d39e00, 0 0 0 3.6em transparent;
  }
}
.btn-danger .btn-spinner {
  animation: btn-glow-danger 1s ease infinite;
}

@keyframes btn-glow-danger {
  0% {
    box-shadow: 0 0 0 0.4em #a61c37, 0 0 0 0.1em #a61c37;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #a61c37, 0 0 0 3.6em transparent;
  }
}
.btn-light .btn-spinner {
  animation: btn-glow-light 1s ease infinite;
}

@keyframes btn-glow-light {
  0% {
    box-shadow: 0 0 0 0.4em #a2a2a2, 0 0 0 0.1em #a2a2a2;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #a2a2a2, 0 0 0 3.6em transparent;
  }
}
.btn-dark .btn-spinner {
  animation: btn-glow-dark 1s ease infinite;
}

@keyframes btn-glow-dark {
  0% {
    box-shadow: 0 0 0 0.4em #0d2d33, 0 0 0 0.1em #0d2d33;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #0d2d33, 0 0 0 3.6em transparent;
  }
}
.btn-gray-100 .btn-spinner {
  animation: btn-glow-gray-100 1s ease infinite;
}

@keyframes btn-glow-gray-100 {
  0% {
    box-shadow: 0 0 0 0.4em #dae0e5, 0 0 0 0.1em #dae0e5;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #dae0e5, 0 0 0 3.6em transparent;
  }
}
.btn-gray-200 .btn-spinner {
  animation: btn-glow-gray-200 1s ease infinite;
}

@keyframes btn-glow-gray-200 {
  0% {
    box-shadow: 0 0 0 0.4em #d5d5d5, 0 0 0 0.1em #d5d5d5;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #d5d5d5, 0 0 0 3.6em transparent;
  }
}
.btn-gray-300 .btn-spinner {
  animation: btn-glow-gray-300 1s ease infinite;
}

@keyframes btn-glow-gray-300 {
  0% {
    box-shadow: 0 0 0 0.4em #c1c9d0, 0 0 0 0.1em #c1c9d0;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #c1c9d0, 0 0 0 3.6em transparent;
  }
}
.btn-gray-400 .btn-spinner {
  animation: btn-glow-gray-400 1s ease infinite;
}

@keyframes btn-glow-gray-400 {
  0% {
    box-shadow: 0 0 0 0.4em #b1bbc4, 0 0 0 0.1em #b1bbc4;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #b1bbc4, 0 0 0 3.6em transparent;
  }
}
.nav-tabs {
  border: 0;
}
.nav-tabs .nav-item .nav-link {
  border: 0;
  padding: 1rem;
}
.nav-tabs .nav-item .nav-link:not(.disabled) {
  color: inherit;
}
.nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #0092b0;
  background: rgba(0, 146, 176, 0.1);
}

.tab-content {
  padding: 1rem;
}

ngb-tabset.nav-center .nav-tabs {
  justify-content: center !important;
  margin-bottom: 1.5rem;
}

ngb-tabset.p-0 .tab-content {
  padding: 1rem 0;
}

.dropdown-toggle {
  position: relative;
}
.dropdown-toggle.btn {
  padding-right: 28px;
}
.dropdown-toggle::after {
  position: absolute;
  top: calc(50% - 2px);
  right: 10px !important;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.08);
}

.dropdown-item {
  padding: 0.42rem 1.5rem;
}

.menu-icon-grid {
  width: 220px;
  padding: 0 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menu-icon-grid > a {
  display: inline-flex;
  width: 6rem;
  flex-direction: column;
  align-items: center;
  padding: 18px 12px;
  color: #1b5e6c;
  border-radius: 4px;
}
.menu-icon-grid > a i {
  font-size: 28px;
  margin-bottom: 4px;
}
.menu-icon-grid > a:hover {
  background: #0092b0;
  color: #fff;
}

.mega-menu {
  position: static;
}
.mega-menu .dropdown-toggle {
  background: transparent;
}
.mega-menu .dropdown-menu {
  width: calc(100% - 120px);
  max-width: 1200px;
  padding: 0;
  overflow: hidden;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}
.mega-menu .dropdown-menu .bg-img {
  background: linear-gradient(to right, #0092b0, #263db5);
}
.mega-menu .dropdown-menu .bg-img, .mega-menu .dropdown-menu .bg-img .title {
  color: #ffffff;
}
.mega-menu .dropdown-menu ul.links {
  list-style: none;
  margin: 0;
  padding: 0;
  column-count: 2;
}
.mega-menu .dropdown-menu ul.links li a {
  display: inline-block;
  margin-bottom: 8px;
  color: #27899d;
}
.mega-menu .dropdown-menu ul.links li a:hover {
  color: #0092b0;
}

@media (max-width: 767px) {
  .mega-menu .dropdown-menu {
    left: 0 !important;
    right: auto !important;
    width: calc(100% - 10px);
  }
}
[dir=rtl] .mega-menu .dropdown-menu {
  left: auto !important;
  right: 16px !important;
}
[dir=rtl] .notification-dropdown.dropdown-menu,
[dir=rtl] .header-account-dropdown.dropdown-menu,
[dir=rtl] .menu-icon-grid-dropdown.dropdown-menu {
  right: auto !important;
  left: 0 !important;
}

.form-group {
  position: relative;
  margin-bottom: 10px;
}
.form-group label {
  font-size: 12px;
  color: #27899d;
  margin-bottom: 4px;
}

.form-control {
  border: initial;
  outline: initial !important;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  color: #17505c;
}
.form-control::placeholder {
  color: #bbb;
}
.form-control.rounded, .form-control.form-control-rounded {
  border-radius: 20px;
}

select.form-control {
  -webkit-appearance: none;
}

.input-group-append .btn {
  height: 34px;
  border-color: #ced4da;
}

[ngbdatepicker] {
  height: 34px;
}

/* checkbox-custom */
.checkbox, .radio {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}
.checkbox:hover input ~ .checkmark, .radio:hover input ~ .checkmark {
  background-color: #bbb;
}
.checkbox input, .radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input:checked ~ .checkmark, .radio input:checked ~ .checkmark {
  background-color: #0092b0;
}
.checkbox input:checked ~ .checkmark:after, .radio input:checked ~ .checkmark:after {
  display: block;
}
.checkbox input[disabled] ~ *, .radio input[disabled] ~ * {
  color: #dee2e6;
}
.checkbox .checkmark, .radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #dee2e6;
}
.checkbox .checkmark:after, .radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  width: 4px;
  bottom: 0;
  margin: auto;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-primary input:checked ~ .checkmark {
  background-color: #0092b0 !important;
}

.checkbox-secondary input:checked ~ .checkmark {
  background-color: #1b5e6c !important;
}

.checkbox-success input:checked ~ .checkmark {
  background-color: #4CAF50 !important;
}

.checkbox-info input:checked ~ .checkmark {
  background-color: #263db5 !important;
}

.checkbox-warning input:checked ~ .checkmark {
  background-color: #FFC107 !important;
}

.checkbox-danger input:checked ~ .checkmark {
  background-color: #d22346 !important;
}

.checkbox-light input:checked ~ .checkmark {
  background-color: #bbb !important;
}

.checkbox-dark input:checked ~ .checkmark {
  background-color: #17505c !important;
}

.checkbox-gray-100 input:checked ~ .checkmark {
  background-color: #f8f9fa !important;
}

.checkbox-gray-200 input:checked ~ .checkmark {
  background-color: #eee !important;
}

.checkbox-gray-300 input:checked ~ .checkmark {
  background-color: #dee2e6 !important;
}

.checkbox-gray-400 input:checked ~ .checkmark {
  background-color: #ced4da !important;
}

.checkbox-outline-primary:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-primary input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-primary .checkmark {
  background: #fff;
  border: 1px solid #0092b0;
}
.checkbox-outline-primary .checkmark:after {
  border-color: #0092b0;
}

.checkbox-outline-secondary:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-secondary input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-secondary .checkmark {
  background: #fff;
  border: 1px solid #1b5e6c;
}
.checkbox-outline-secondary .checkmark:after {
  border-color: #1b5e6c;
}

.checkbox-outline-success:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-success input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-success .checkmark {
  background: #fff;
  border: 1px solid #4CAF50;
}
.checkbox-outline-success .checkmark:after {
  border-color: #4CAF50;
}

.checkbox-outline-info:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-info input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-info .checkmark {
  background: #fff;
  border: 1px solid #263db5;
}
.checkbox-outline-info .checkmark:after {
  border-color: #263db5;
}

.checkbox-outline-warning:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-warning input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-warning .checkmark {
  background: #fff;
  border: 1px solid #FFC107;
}
.checkbox-outline-warning .checkmark:after {
  border-color: #FFC107;
}

.checkbox-outline-danger:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-danger input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-danger .checkmark {
  background: #fff;
  border: 1px solid #d22346;
}
.checkbox-outline-danger .checkmark:after {
  border-color: #d22346;
}

.checkbox-outline-light:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-light input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-light .checkmark {
  background: #fff;
  border: 1px solid #bbb;
}
.checkbox-outline-light .checkmark:after {
  border-color: #bbb;
}

.checkbox-outline-dark:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-dark input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-dark .checkmark {
  background: #fff;
  border: 1px solid #17505c;
}
.checkbox-outline-dark .checkmark:after {
  border-color: #17505c;
}

.checkbox-outline-gray-100:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-gray-100 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-gray-100 .checkmark {
  background: #fff;
  border: 1px solid #f8f9fa;
}
.checkbox-outline-gray-100 .checkmark:after {
  border-color: #f8f9fa;
}

.checkbox-outline-gray-200:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-gray-200 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-gray-200 .checkmark {
  background: #fff;
  border: 1px solid #eee;
}
.checkbox-outline-gray-200 .checkmark:after {
  border-color: #eee;
}

.checkbox-outline-gray-300:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-gray-300 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-gray-300 .checkmark {
  background: #fff;
  border: 1px solid #dee2e6;
}
.checkbox-outline-gray-300 .checkmark:after {
  border-color: #dee2e6;
}

.checkbox-outline-gray-400:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-gray-400 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-gray-400 .checkmark {
  background: #fff;
  border: 1px solid #ced4da;
}
.checkbox-outline-gray-400 .checkmark:after {
  border-color: #ced4da;
}

.radio .checkmark {
  border-radius: 50%;
}
.radio .checkmark:after {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: white;
}

.radio-reverse {
  padding-left: 0;
  padding-right: 28px;
}
.radio-reverse .checkmark {
  left: auto;
  right: 0;
}

.radio-primary input:checked ~ .checkmark {
  background-color: #0092b0;
}

.radio-secondary input:checked ~ .checkmark {
  background-color: #1b5e6c;
}

.radio-success input:checked ~ .checkmark {
  background-color: #4CAF50;
}

.radio-info input:checked ~ .checkmark {
  background-color: #263db5;
}

.radio-warning input:checked ~ .checkmark {
  background-color: #FFC107;
}

.radio-danger input:checked ~ .checkmark {
  background-color: #d22346;
}

.radio-light input:checked ~ .checkmark {
  background-color: #bbb;
}

.radio-dark input:checked ~ .checkmark {
  background-color: #17505c;
}

.radio-gray-100 input:checked ~ .checkmark {
  background-color: #f8f9fa;
}

.radio-gray-200 input:checked ~ .checkmark {
  background-color: #eee;
}

.radio-gray-300 input:checked ~ .checkmark {
  background-color: #dee2e6;
}

.radio-gray-400 input:checked ~ .checkmark {
  background-color: #ced4da;
}

.radio-outline-primary:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-primary input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-primary .checkmark {
  background: #fff;
  border: 1px solid #0092b0;
}
.radio-outline-primary .checkmark:after {
  border: 0;
  background: #0092b0;
}

.radio-outline-secondary:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-secondary input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-secondary .checkmark {
  background: #fff;
  border: 1px solid #1b5e6c;
}
.radio-outline-secondary .checkmark:after {
  border: 0;
  background: #1b5e6c;
}

.radio-outline-success:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-success input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-success .checkmark {
  background: #fff;
  border: 1px solid #4CAF50;
}
.radio-outline-success .checkmark:after {
  border: 0;
  background: #4CAF50;
}

.radio-outline-info:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-info input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-info .checkmark {
  background: #fff;
  border: 1px solid #263db5;
}
.radio-outline-info .checkmark:after {
  border: 0;
  background: #263db5;
}

.radio-outline-warning:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-warning input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-warning .checkmark {
  background: #fff;
  border: 1px solid #FFC107;
}
.radio-outline-warning .checkmark:after {
  border: 0;
  background: #FFC107;
}

.radio-outline-danger:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-danger input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-danger .checkmark {
  background: #fff;
  border: 1px solid #d22346;
}
.radio-outline-danger .checkmark:after {
  border: 0;
  background: #d22346;
}

.radio-outline-light:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-light input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-light .checkmark {
  background: #fff;
  border: 1px solid #bbb;
}
.radio-outline-light .checkmark:after {
  border: 0;
  background: #bbb;
}

.radio-outline-dark:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-dark input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-dark .checkmark {
  background: #fff;
  border: 1px solid #17505c;
}
.radio-outline-dark .checkmark:after {
  border: 0;
  background: #17505c;
}

.radio-outline-gray-100:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-gray-100 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-gray-100 .checkmark {
  background: #fff;
  border: 1px solid #f8f9fa;
}
.radio-outline-gray-100 .checkmark:after {
  border: 0;
  background: #f8f9fa;
}

.radio-outline-gray-200:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-gray-200 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-gray-200 .checkmark {
  background: #fff;
  border: 1px solid #eee;
}
.radio-outline-gray-200 .checkmark:after {
  border: 0;
  background: #eee;
}

.radio-outline-gray-300:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-gray-300 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-gray-300 .checkmark {
  background: #fff;
  border: 1px solid #dee2e6;
}
.radio-outline-gray-300 .checkmark:after {
  border: 0;
  background: #dee2e6;
}

.radio-outline-gray-400:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-gray-400 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-gray-400 .checkmark {
  background: #fff;
  border: 1px solid #ced4da;
}
.radio-outline-gray-400 .checkmark:after {
  border: 0;
  background: #ced4da;
}

.switch {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  height: 16px;
}
.switch span:not(.slider) {
  position: relative;
  top: -2px;
  cursor: pointer;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  width: 42px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #dee2e6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: -1px;
  bottom: -4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.switch input:checked + .slider {
  background-color: #0092b0;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #0092b0;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.switch-primary input:checked + .slider {
  background-color: #0092b0;
}
.switch-primary input:focus + .slider {
  box-shadow: 0 0 1px #0092b0;
}

.switch-secondary input:checked + .slider {
  background-color: #1b5e6c;
}
.switch-secondary input:focus + .slider {
  box-shadow: 0 0 1px #1b5e6c;
}

.switch-success input:checked + .slider {
  background-color: #4CAF50;
}
.switch-success input:focus + .slider {
  box-shadow: 0 0 1px #4CAF50;
}

.switch-info input:checked + .slider {
  background-color: #263db5;
}
.switch-info input:focus + .slider {
  box-shadow: 0 0 1px #263db5;
}

.switch-warning input:checked + .slider {
  background-color: #FFC107;
}
.switch-warning input:focus + .slider {
  box-shadow: 0 0 1px #FFC107;
}

.switch-danger input:checked + .slider {
  background-color: #d22346;
}
.switch-danger input:focus + .slider {
  box-shadow: 0 0 1px #d22346;
}

.switch-light input:checked + .slider {
  background-color: #bbb;
}
.switch-light input:focus + .slider {
  box-shadow: 0 0 1px #bbb;
}

.switch-dark input:checked + .slider {
  background-color: #17505c;
}
.switch-dark input:focus + .slider {
  box-shadow: 0 0 1px #17505c;
}

.switch-gray-100 input:checked + .slider {
  background-color: #f8f9fa;
}
.switch-gray-100 input:focus + .slider {
  box-shadow: 0 0 1px #f8f9fa;
}

.switch-gray-200 input:checked + .slider {
  background-color: #eee;
}
.switch-gray-200 input:focus + .slider {
  box-shadow: 0 0 1px #eee;
}

.switch-gray-300 input:checked + .slider {
  background-color: #dee2e6;
}
.switch-gray-300 input:focus + .slider {
  box-shadow: 0 0 1px #dee2e6;
}

.switch-gray-400 input:checked + .slider {
  background-color: #ced4da;
}
.switch-gray-400 input:focus + .slider {
  box-shadow: 0 0 1px #ced4da;
}

[dir=rtl] .form-check {
  padding-left: 0;
  padding-right: 1.25rem;
}
[dir=rtl] .form-check-input {
  margin-left: 0;
  margin-right: -1.25rem;
}
[dir=rtl] .switch {
  padding-left: 0;
  padding-right: 50px;
}
[dir=rtl] .checkbox,
[dir=rtl] .radio {
  padding-left: 0px;
  padding-right: 28px;
}
[dir=rtl] .checkbox .checkmark,
[dir=rtl] .radio .checkmark {
  left: auto;
  right: 0;
}

.icon-regular {
  font-size: 18px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.link-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #17505c;
}
.link-icon i {
  margin: 0 8px;
}

.spinner-glow {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: #dee2e6;
  border-radius: 50%;
  margin: 4px auto;
  border: 2px solid transparent;
  animation: glow 1s ease infinite;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0.4em #a1a2a1, 0 0 0 0.1em #a1a2a1;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #605556;
  }
  100% {
    box-shadow: 0 0 0 0.4em #a1a2a1, 0 0 0 3.6em transparent;
  }
}
.spinner-glow-primary {
  background: rgba(0, 146, 176, 0.45);
  animation: glow-primary 1s ease infinite;
}

.spinner-glow-secondary {
  background: rgba(27, 94, 108, 0.45);
  animation: glow-secondary 1s ease infinite;
}

.spinner-glow-success {
  background: rgba(76, 175, 80, 0.45);
  animation: glow-success 1s ease infinite;
}

.spinner-glow-info {
  background: rgba(38, 61, 181, 0.45);
  animation: glow-info 1s ease infinite;
}

.spinner-glow-warning {
  background: rgba(255, 193, 7, 0.45);
  animation: glow-warning 1s ease infinite;
}

.spinner-glow-danger {
  background: rgba(210, 35, 70, 0.45);
  animation: glow-danger 1s ease infinite;
}

.spinner-glow-light {
  background: rgba(187, 187, 187, 0.45);
  animation: glow-light 1s ease infinite;
}

.spinner-glow-dark {
  background: rgba(23, 80, 92, 0.45);
  animation: glow-dark 1s ease infinite;
}

.spinner-glow-gray-100 {
  background: rgba(248, 249, 250, 0.45);
  animation: glow-gray-100 1s ease infinite;
}

.spinner-glow-gray-200 {
  background: rgba(238, 238, 238, 0.45);
  animation: glow-gray-200 1s ease infinite;
}

.spinner-glow-gray-300 {
  background: rgba(222, 226, 230, 0.45);
  animation: glow-gray-300 1s ease infinite;
}

.spinner-glow-gray-400 {
  background: rgba(206, 212, 218, 0.45);
  animation: glow-gray-400 1s ease infinite;
}

@keyframes glow-primary {
  0% {
    box-shadow: 0 0 0 0.4em rgba(0, 146, 176, 0.45), 0 0 0 0.1em rgba(0, 146, 176, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(0, 146, 176, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(0, 146, 176, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-secondary {
  0% {
    box-shadow: 0 0 0 0.4em rgba(27, 94, 108, 0.45), 0 0 0 0.1em rgba(27, 94, 108, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(27, 94, 108, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(27, 94, 108, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-success {
  0% {
    box-shadow: 0 0 0 0.4em rgba(76, 175, 80, 0.45), 0 0 0 0.1em rgba(76, 175, 80, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(76, 175, 80, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(76, 175, 80, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-info {
  0% {
    box-shadow: 0 0 0 0.4em rgba(38, 61, 181, 0.45), 0 0 0 0.1em rgba(38, 61, 181, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(38, 61, 181, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(38, 61, 181, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-warning {
  0% {
    box-shadow: 0 0 0 0.4em rgba(255, 193, 7, 0.45), 0 0 0 0.1em rgba(255, 193, 7, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(255, 193, 7, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(255, 193, 7, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-danger {
  0% {
    box-shadow: 0 0 0 0.4em rgba(210, 35, 70, 0.45), 0 0 0 0.1em rgba(210, 35, 70, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(210, 35, 70, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(210, 35, 70, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-light {
  0% {
    box-shadow: 0 0 0 0.4em rgba(187, 187, 187, 0.45), 0 0 0 0.1em rgba(187, 187, 187, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(187, 187, 187, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(187, 187, 187, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-dark {
  0% {
    box-shadow: 0 0 0 0.4em rgba(23, 80, 92, 0.45), 0 0 0 0.1em rgba(23, 80, 92, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(23, 80, 92, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(23, 80, 92, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-gray-100 {
  0% {
    box-shadow: 0 0 0 0.4em rgba(248, 249, 250, 0.45), 0 0 0 0.1em rgba(248, 249, 250, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(248, 249, 250, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(248, 249, 250, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-gray-200 {
  0% {
    box-shadow: 0 0 0 0.4em rgba(238, 238, 238, 0.45), 0 0 0 0.1em rgba(238, 238, 238, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(238, 238, 238, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(238, 238, 238, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-gray-300 {
  0% {
    box-shadow: 0 0 0 0.4em rgba(222, 226, 230, 0.45), 0 0 0 0.1em rgba(222, 226, 230, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(222, 226, 230, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(222, 226, 230, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-gray-400 {
  0% {
    box-shadow: 0 0 0 0.4em rgba(206, 212, 218, 0.45), 0 0 0 0.1em rgba(206, 212, 218, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(206, 212, 218, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(206, 212, 218, 0.75), 0 0 0 3.6em transparent;
  }
}
.spinner {
  display: inline-block;
  font-size: 10px;
  margin: auto;
  text-indent: -9999em;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  position: relative;
  -webkit-animation: spin 1.4s infinite linear;
  animation: spin 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before {
  width: 50%;
  height: 50%;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.spinner:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.spinner-primary {
  background: #0092b0;
  background: -moz-linear-gradient(left, #0092b0 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #0092b0 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #0092b0 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #0092b0 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #0092b0 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-primary:before {
  background: #0092b0;
}

.spinner-secondary {
  background: #1b5e6c;
  background: -moz-linear-gradient(left, #1b5e6c 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #1b5e6c 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #1b5e6c 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #1b5e6c 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #1b5e6c 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-secondary:before {
  background: #1b5e6c;
}

.spinner-success {
  background: #4CAF50;
  background: -moz-linear-gradient(left, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-success:before {
  background: #4CAF50;
}

.spinner-info {
  background: #263db5;
  background: -moz-linear-gradient(left, #263db5 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #263db5 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #263db5 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #263db5 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #263db5 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-info:before {
  background: #263db5;
}

.spinner-warning {
  background: #FFC107;
  background: -moz-linear-gradient(left, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-warning:before {
  background: #FFC107;
}

.spinner-danger {
  background: #d22346;
  background: -moz-linear-gradient(left, #d22346 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #d22346 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #d22346 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #d22346 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #d22346 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-danger:before {
  background: #d22346;
}

.spinner-light {
  background: #bbb;
  background: -moz-linear-gradient(left, #bbb 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #bbb 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #bbb 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #bbb 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #bbb 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-light:before {
  background: #bbb;
}

.spinner-dark {
  background: #17505c;
  background: -moz-linear-gradient(left, #17505c 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #17505c 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #17505c 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #17505c 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #17505c 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-dark:before {
  background: #17505c;
}

.spinner-gray-100 {
  background: #f8f9fa;
  background: -moz-linear-gradient(left, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-gray-100:before {
  background: #f8f9fa;
}

.spinner-gray-200 {
  background: #eee;
  background: -moz-linear-gradient(left, #eee 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #eee 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #eee 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #eee 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #eee 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-gray-200:before {
  background: #eee;
}

.spinner-gray-300 {
  background: #dee2e6;
  background: -moz-linear-gradient(left, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-gray-300:before {
  background: #dee2e6;
}

.spinner-gray-400 {
  background: #ced4da;
  background: -moz-linear-gradient(left, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-gray-400:before {
  background: #ced4da;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-bubble {
  display: inline-block;
  font-size: 8px;
  margin: 30px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: bubble-circle 1.3s infinite linear;
  animation: bubble-circle 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner-bubble-primary {
  color: #0092b0;
}

.spinner-bubble-secondary {
  color: #1b5e6c;
}

.spinner-bubble-success {
  color: #4CAF50;
}

.spinner-bubble-info {
  color: #263db5;
}

.spinner-bubble-warning {
  color: #FFC107;
}

.spinner-bubble-danger {
  color: #d22346;
}

.spinner-bubble-light {
  color: #bbb;
}

.spinner-bubble-dark {
  color: #17505c;
}

.spinner-bubble-gray-100 {
  color: #f8f9fa;
}

.spinner-bubble-gray-200 {
  color: #eee;
}

.spinner-bubble-gray-300 {
  color: #dee2e6;
}

.spinner-bubble-gray-400 {
  color: #ced4da;
}

@-webkit-keyframes bubble-circle {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes bubble-circle {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
.loader-bubble,
.loader-bubble:before,
.loader-bubble:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: bubble-horz 1.8s infinite ease-in-out;
  animation: bubble-horz 1.8s infinite ease-in-out;
}

.loader-bubble {
  display: inline-block;
  font-size: 6px;
  margin: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-bubble-primary {
  color: #0092b0;
}

.loader-bubble-secondary {
  color: #1b5e6c;
}

.loader-bubble-success {
  color: #4CAF50;
}

.loader-bubble-info {
  color: #263db5;
}

.loader-bubble-warning {
  color: #FFC107;
}

.loader-bubble-danger {
  color: #d22346;
}

.loader-bubble-light {
  color: #bbb;
}

.loader-bubble-dark {
  color: #17505c;
}

.loader-bubble-gray-100 {
  color: #f8f9fa;
}

.loader-bubble-gray-200 {
  color: #eee;
}

.loader-bubble-gray-300 {
  color: #dee2e6;
}

.loader-bubble-gray-400 {
  color: #ced4da;
}

.loader-bubble:before,
.loader-bubble:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader-bubble:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-bubble:after {
  left: 3.5em;
}

@-webkit-keyframes bubble-horz {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes bubble-horz {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.alert {
  background: #fff;
  border-radius: 10px;
}
.alert .close:focus {
  outline: 0;
}

.alert-card {
  border: none;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #ced4da;
}
.alert-card.alert-success {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50;
}
.alert-card.alert-warning {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #FFC107;
}
.alert-card.alert-info {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #263db5;
}
.alert-card.alert-danger {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #d22346;
}
.alert-card.alert-dark {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #27899d;
}

.toast-container .ngx-toastr {
  color: #17505c;
}
.toast-container .toast {
  border-radius: 10px;
  color: #17505c;
  opacity: 1 !important;
}
.toast-container .toast-close-button {
  position: absolute;
  color: #ced4da;
  top: -1px;
  right: 8px;
}
.toast-container .toast-progress {
  height: 2px;
  opacity: 1;
}
.toast-container .toast-success {
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50;
  background-image: url("../../../../images/checkmark.svg");
}
.toast-container .toast-success .toast-progress {
  background: #4CAF50;
}
.toast-container .toast-success:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50;
}
.toast-container .toast-warning {
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #FFC107;
  background-image: url("../../../../images/danger.svg");
}
.toast-container .toast-warning .toast-progress {
  background: #FFC107;
}
.toast-container .toast-warning:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #FFC107;
}
.toast-container .toast-info {
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #263db5;
  background-image: url("../../../../images/info.svg");
}
.toast-container .toast-info .toast-progress {
  background: #263db5;
}
.toast-container .toast-info:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #263db5;
}
.toast-container .toast-error {
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #d22346;
  background-image: url("../../../../images/close.svg");
}
.toast-container .toast-error .toast-progress {
  background: #d22346;
}
.toast-container .toast-error:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #d22346;
}

.toast-top-right {
  right: 30px;
}

.sidebar-container {
  position: relative;
  overflow: hidden;
  min-height: 400px;
}
.sidebar-container .sidebar-content {
  position: relative;
  height: 100%;
  transition: all 0.3s ease-in;
}
.sidebar-container .sidebar-content:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.sidebar-container .sidebar {
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in;
  background: #fff;
}
.sidebar-container .sidebar .sidebar-close {
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  z-index: 999;
}

@media (max-width: 767px) {
  .sidebar-container .sidebar .sidebar-close {
    display: block;
  }
}
.ng2-tag-input.primary {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  cursor: text;
  border-bottom: 1px solid #00687d !important;
}
.ng2-tag-input.primary:focus {
  outline: 0;
}
.ng2-tag-input.primary.ng2-tag-input--dropping {
  opacity: 0.7;
}
.ng2-tag-input.primary.ng2-tag-input--loading {
  border: none;
}
.ng2-tag-input.primary.ng2-tag-input--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.ng2-tag-input.primary .ng2-tags-container {
  flex-wrap: wrap;
  display: flex;
}

.ng2-tag-input.primary tag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Nunito", sans-serif;
  color: #fff;
  border-radius: 30px;
  padding: 0 1rem;
  height: auto;
  line-height: auto;
  background: #0092b0;
  user-select: none;
  overflow: hidden;
  outline: 0;
  cursor: pointer;
  position: relative;
}
.ng2-tag-input.primary tag:not(.readonly):not(.tag--editing):focus {
  background: #00687d;
}
.ng2-tag-input.primary tag:not(.readonly):not(.tag--editing):active {
  background: #00687d;
}
.ng2-tag-input.primary tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background: #00687d;
  color: #fff;
}
.ng2-tag-input.primary tag.readonly {
  cursor: default;
}
.ng2-tag-input.primary tag.readonly:focus, .ng2-tag-input.primary tag:focus {
  outline: 0;
}
.ng2-tag-input.primary tag.tag--editing {
  cursor: text;
}

.ng2-tag-input.primary tag delete-icon {
  transition: all 0.35s;
  text-align: right;
}
.ng2-tag-input.primary tag delete-icon path {
  fill: #fff;
}
.ng2-tag-input.primary tag delete-icon svg {
  vertical-align: bottom;
  height: 34px;
}

img-cropper input[type=file] {
  margin-bottom: 1rem;
}

.ngx-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}
.ngx-pagination a:focus {
  outline: none;
}
.ngx-pagination .pagination-previous,
.ngx-pagination .pagination-next {
  border-radius: 10px !important;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination .pagination-previous a,
.ngx-pagination .pagination-next a {
  color: #17505c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination .pagination-previous a:hover,
.ngx-pagination .pagination-next a:hover {
  background: transparent;
}
.ngx-pagination .pagination-previous a span,
.ngx-pagination .pagination-next a span {
  line-height: 100%;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) {
  border: none;
  background: transparent;
  border-radius: 50% !important;
  margin: 0 4px !important;
  text-align: center;
  height: 36px;
  width: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen):hover {
  background: transparent;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) span {
  line-height: 100%;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) a {
  color: #17505c;
  height: 36px;
  width: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) a:hover {
  background: transparent;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) a span {
  line-height: 100%;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen).current {
  border: 1px solid #0092b0;
  background: #0092b0;
  color: #fff;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before,
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  font-family: "iconsmind" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "\f077" !important;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "\f07d" !important;
}

[dir=rtl] .pagination-next, [dir=rtl] .pagination-previous {
  transform: rotate(180deg);
}

quill-editor .ql-toolbar.ql-snow,
quill-editor .ql-container.ql-snow {
  border-color: #eee;
  background: #f8f9fa;
}
quill-editor .ql-container {
  min-height: 120px;
}
quill-editor .ql-toolbar.ql-snow {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
quill-editor .ql-container.ql-snow {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
quill-editor.rounded .ql-toolbar.ql-snow {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
quill-editor.rounded .ql-container.ql-snow {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

form-wizard .card {
  overflow: hidden;
}
form-wizard .card .card-header {
  font-size: 1rem;
}
form-wizard .card .card-block {
  padding: 1.25rem 1.25rem 0;
}
form-wizard .card .nav .nav-item {
  padding: 0;
  border: 0;
}
form-wizard .card .nav .nav-item.active {
  font-weight: normal;
}
form-wizard .card .nav .nav-item.active a {
  border-bottom-color: #0092b0 !important;
  background: rgba(0, 146, 176, 0.1);
}
form-wizard .card .nav .nav-item a {
  border-bottom: 2px solid #dee2e6;
  height: 56px;
  display: block;
  line-height: 56px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  transition: all 0.2s ease-in;
}
form-wizard .card .card-footer .btn {
  color: #0092b0;
  background-color: transparent;
  background-image: none;
  border-color: #0092b0;
}
form-wizard .card .card-footer .btn:hover {
  color: #fff;
  background-color: #0092b0;
  border-color: #0092b0;
}
form-wizard .card .card-footer .btn:focus, form-wizard .card .card-footer .btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.5);
}
form-wizard .card .card-footer .btn.disabled, form-wizard .card .card-footer .btn:disabled {
  color: #0092b0;
  background-color: transparent;
}
form-wizard .card .card-footer .btn:not(:disabled):not(.disabled):active, form-wizard .card .card-footer .btn:not(:disabled):not(.disabled).active, .show > form-wizard .card .card-footer .btn.dropdown-toggle {
  color: #fff;
  background-color: #0092b0;
  border-color: #0092b0;
}
form-wizard .card .card-footer .btn:not(:disabled):not(.disabled):active:focus, form-wizard .card .card-footer .btn:not(:disabled):not(.disabled).active:focus, .show > form-wizard .card .card-footer .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 146, 176, 0.5);
}

.badge {
  font-weight: 600;
}

.badge-outline-primary {
  background: unset;
  border: 1px solid #0092b0;
  color: #0092b0;
}

.badge-outline-secondary {
  background: unset;
  border: 1px solid #1b5e6c;
  color: #1b5e6c;
}

.badge-outline-success {
  background: unset;
  border: 1px solid #4CAF50;
  color: #4CAF50;
}

.badge-outline-info {
  background: unset;
  border: 1px solid #263db5;
  color: #263db5;
}

.badge-outline-warning {
  background: unset;
  border: 1px solid #FFC107;
  color: #FFC107;
}

.badge-outline-danger {
  background: unset;
  border: 1px solid #d22346;
  color: #d22346;
}

.badge-outline-light {
  background: unset;
  border: 1px solid #bbb;
  color: #bbb;
}

.badge-outline-dark {
  background: unset;
  border: 1px solid #17505c;
  color: #17505c;
}

.badge-outline-gray-100 {
  background: unset;
  border: 1px solid #f8f9fa;
  color: #f8f9fa;
}

.badge-outline-gray-200 {
  background: unset;
  border: 1px solid #eee;
  color: #eee;
}

.badge-outline-gray-300 {
  background: unset;
  border: 1px solid #dee2e6;
  color: #dee2e6;
}

.badge-outline-gray-400 {
  background: unset;
  border: 1px solid #ced4da;
  color: #ced4da;
}

.badge-top-container {
  position: relative;
}
.badge-top-container .badge {
  position: absolute;
  top: 2px;
  right: 6px;
  border-radius: 10px;
  z-index: 10;
}

.modal-dialog {
  animation-name: zoomIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.popover {
  border: none;
  animation-name: zoomIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
}
.popover .arrow::before {
  border-color: rgba(0, 0, 0, 0);
}

ngb-rating .star {
  position: relative;
  display: inline-block;
}
ngb-rating .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
}

.rating-primary {
  color: #0092b0;
}
.rating-primary .star {
  color: #bbb;
}
.rating-primary .full, .rating-primary .half {
  color: #0092b0;
}

.rating-secondary {
  color: #1b5e6c;
}
.rating-secondary .star {
  color: #bbb;
}
.rating-secondary .full, .rating-secondary .half {
  color: #1b5e6c;
}

.rating-success {
  color: #4CAF50;
}
.rating-success .star {
  color: #bbb;
}
.rating-success .full, .rating-success .half {
  color: #4CAF50;
}

.rating-info {
  color: #263db5;
}
.rating-info .star {
  color: #bbb;
}
.rating-info .full, .rating-info .half {
  color: #263db5;
}

.rating-warning {
  color: #FFC107;
}
.rating-warning .star {
  color: #bbb;
}
.rating-warning .full, .rating-warning .half {
  color: #FFC107;
}

.rating-danger {
  color: #d22346;
}
.rating-danger .star {
  color: #bbb;
}
.rating-danger .full, .rating-danger .half {
  color: #d22346;
}

.rating-light {
  color: #bbb;
}
.rating-light .star {
  color: #bbb;
}
.rating-light .full, .rating-light .half {
  color: #bbb;
}

.rating-dark {
  color: #17505c;
}
.rating-dark .star {
  color: #bbb;
}
.rating-dark .full, .rating-dark .half {
  color: #17505c;
}

.rating-gray-100 {
  color: #f8f9fa;
}
.rating-gray-100 .star {
  color: #bbb;
}
.rating-gray-100 .full, .rating-gray-100 .half {
  color: #f8f9fa;
}

.rating-gray-200 {
  color: #eee;
}
.rating-gray-200 .star {
  color: #bbb;
}
.rating-gray-200 .full, .rating-gray-200 .half {
  color: #eee;
}

.rating-gray-300 {
  color: #dee2e6;
}
.rating-gray-300 .star {
  color: #bbb;
}
.rating-gray-300 .full, .rating-gray-300 .half {
  color: #dee2e6;
}

.rating-gray-400 {
  color: #ced4da;
}
.rating-gray-400 .star {
  color: #bbb;
}
.rating-gray-400 .full, .rating-gray-400 .half {
  color: #ced4da;
}

.search-ui {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 0.75rem 1.5rem 1.5rem 4.5rem;
  z-index: 9999;
}
.search-ui .search-header .logo {
  height: 64px;
  width: auto;
}
.search-ui input.search-input {
  font-size: 4rem;
  font-weight: 600;
  border: 0;
  margin-bottom: 1.5rem;
}
.search-ui input.search-input:focus {
  outline: 0;
}
.search-ui input.search-input::placeholder {
  color: #ced4da;
}
.search-ui .search-title {
  margin-bottom: 1.25rem;
}
.search-ui .search-title span {
  font-weight: 600;
}

@media (max-width: 767px) {
  .search-ui {
    padding: 1rem;
  }
}
.customizer {
  position: fixed;
  z-index: 9999;
  top: 100px;
  right: -380px;
  transition: 0.3s all ease-in-out;
  width: 380px;
}
.customizer.open {
  right: 0;
}
.customizer .handle {
  position: absolute;
  display: flex;
  top: 8px;
  left: -36px;
  background: #0092b0;
  cursor: pointer;
  padding: 10px 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.06);
  color: #fff;
  font-size: 20px;
  transition: 0.3s all ease-in-out;
}
.customizer .handle:hover {
  background: #0092b0;
  color: #fff;
}
.customizer .customizer-body {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
  overflow-x: visible;
}
.customizer .customizer-body .layouts {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.customizer .customizer-body .layouts .layout-box {
  position: relative;
  margin: 0 8px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  width: calc(50% - 16px);
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.customizer .customizer-body .layouts .layout-box img {
  width: 180px;
}
.customizer .customizer-body .layouts .layout-box i {
  display: none;
  position: absolute;
  top: 0;
  text-align: center;
  right: 0;
  height: 24px;
  font-size: 18px;
  line-height: 24px;
  width: 32px;
  color: #ffffff;
  background: #0092b0;
  border-radius: 0 0 0 6px;
}
.customizer .customizer-body .layouts .layout-box.active {
  border: 1px solid #0092b0;
}
.customizer .customizer-body .layouts .layout-box.active i {
  display: inline-block;
}
.customizer .customizer-body .colors {
  display: flex;
  flex-wrap: wrap;
}
.customizer .customizer-body .colors .color {
  width: 36px;
  height: 36px;
  display: inline-block;
  border-radius: 50%;
  margin: 8px;
  text-align: center;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}
.customizer .customizer-body .colors .color.purple {
  background: #0092b0;
}
.customizer .customizer-body .colors .color.blue {
  background: #2f47c2;
}
.customizer .customizer-body .colors .color i {
  display: none;
  color: #ffffff;
  font-size: 18px;
  line-height: 36px;
}
.customizer .customizer-body .colors .color.active i {
  display: unset;
}

@media (max-width: 767px) {
  .customizer {
    width: 280px;
    right: -280px;
  }
}
.chat-sidebar-container {
  height: calc(100vh - 140px);
  min-height: unset;
}
.chat-sidebar-container .chat-topbar {
  height: 52px;
}
.chat-sidebar-container .chat-content-wrap .chat-content {
  padding: 1.5rem 1.5rem 0;
  position: relative;
  height: calc(100vh - 335px);
}
.chat-sidebar-container .chat-content-wrap .chat-content .message {
  position: relative;
  background: #f8f9fa;
  border-radius: 10px;
  padding: 1rem;
}
.chat-sidebar-container .chat-content-wrap .chat-content .message:before {
  position: absolute;
  content: "";
  top: 14px;
  right: -5px;
  background: transparent;
  border-style: solid;
  border-width: 0 10px 10px 0px;
  border-color: transparent transparent #f8f9fa transparent;
  transform: rotate(-135deg);
}
.chat-sidebar-container .chat-content-wrap .chat-content .user .message:before {
  left: -5px;
  right: auto;
  transform: rotate(45deg);
}
.chat-sidebar-container .chat-content-wrap .chat-content .chat-input-area {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.chat-sidebar-container .chat-content-wrap .spinner-glow {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 10px);
}
.chat-sidebar-container .chat-sidebar-wrap {
  width: 260px;
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable {
  position: relative;
  height: calc(100vh - 190px);
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable .contact {
  position: relative;
  cursor: pointer;
  transition: 0.15s all ease-in;
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable .contact:hover {
  background: #f8f9fa;
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable .contact:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  right: 30px;
  background: #bbb;
  border-radius: 50%;
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable .contact.online:before {
  background: #4CAF50;
}

.invoice-summary {
  width: 220px;
  text-align: right;
}
.invoice-summary.invoice-summary-input {
  width: 320px;
}
.invoice-summary.invoice-summary-input p span, .invoice-summary.invoice-summary-input h5 span {
  width: 180px;
}
.invoice-summary p, .invoice-summary h5 {
  display: flex;
  justify-content: flex-end;
}
.invoice-summary p span, .invoice-summary h5 span {
  width: 120px;
}
.invoice-summary p span .small-input, .invoice-summary h5 span .small-input {
  display: inline-block;
  width: 3rem;
  margin: 0 0.75rem;
}

@media print {
  body * {
    visibility: hidden;
  }

  .main-content-wrap {
    width: 100% !important;
  }

  #print-area, #print-area * {
    visibility: visible;
  }

  #print-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
[dir=rtl] .invoice-summary {
  text-align: left !important;
}

.inbox-main-sidebar-container {
  padding: 15px;
}
.inbox-main-sidebar-container .inbox-main-sidebar {
  width: 180px;
  height: 100%;
  overflow: hidden;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a {
  padding: 0.66rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #17505c;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a.active {
  color: #0092b0;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a:hover {
  color: #0092b0;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a i {
  margin-right: 8px;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container {
  border-radius: 10px;
  min-height: calc(100vh - 150px);
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar-content .inbox-topbar {
  height: 52px;
  display: flex;
  flex-direction: row;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar-content .inbox-details {
  padding: 1.5rem 2rem;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar {
  overflow: hidden;
  width: 360px;
  height: 100%;
  border-right: 1px solid #eee;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 1.25rem 1rem;
  cursor: pointer;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item:hover {
  background: #f8f9fa;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item .avatar {
  width: 15%;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item .details {
  width: 60%;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item .date {
  width: 25%;
  font-size: 10px;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item .date span {
  float: right;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .avatar img {
  margin: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .name {
  font-size: 12px;
  display: block;
}

@media (max-width: 767px) {
  .mail-item {
    padding: 1rem 0.5rem !important;
  }

  .inbox-secondary-sidebar {
    width: 280px !important;
  }
}
[dir=rtl] .inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a i {
  margin-right: 0;
  margin-left: 8px;
}

.ul-contact-page__profile {
  display: flex;
  align-items: center;
}
.ul-contact-page__profile .user-profile {
  height: 150px;
  width: 150px;
}

.ul-contact-page__info {
  /* margin-right: 2px; */
  margin-left: 23px;
  width: calc(100% - 126px);
}

.ul-form-input {
  width: 50%;
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 425px) {
  .ul-contact-page__profile {
    text-align: center;
    display: block;
  }
  .ul-contact-page__profile .user-profile {
    margin: auto;
  }

  .ul-contact-page__info {
    margin-left: 0;
    width: 100%;
  }
}
.list-horizontal .list-item .list-thumb img {
  height: 74px;
  object-fit: cover;
}
.list-horizontal .list-item .item-title {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.list-horizontal .list-item a {
  color: #17505c;
}

.list-grid .list-item .list-thumb img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}
.list-grid .list-item .card-body {
  display: block !important;
}
.list-grid .list-item .item-title {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 300px;
}
.list-grid .list-item a {
  color: #17505c;
}
.list-grid .list-item .item-badges,
.list-grid .list-item .item-actions {
  position: absolute;
  top: 16px;
}
.list-grid .list-item .item-actions {
  right: 16px;
}
.list-grid .list-item .item-badges {
  left: 16px;
}
.list-grid .list-item .item-select {
  display: none;
}

@media (max-width: 991px) {
  .list-horizontal .list-item .list-thumb img {
    height: 100%;
    width: 100px;
  }
  .list-horizontal .list-item .item-title {
    max-width: 200px;
  }
}
@media (max-width: 576px) {
  .list-horizontal .list-item .item-title {
    max-width: 150px;
  }
}
.user-profile .header-cover {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
}
.user-profile .header-cover::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
}
.user-profile .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  z-index: 9;
}
.user-profile .profile-picture {
  border-radius: 50%;
  border: 4px solid #fff;
}
.user-profile .profile-nav {
  justify-content: center;
}

.timeline {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}
.timeline .timeline-item {
  position: relative;
  width: 50%;
  display: inline-block;
}
.timeline .timeline-item:nth-child(even) {
  padding: 0 3rem 3rem 0;
}
.timeline .timeline-item:nth-child(even) .timeline-badge {
  left: calc(100% - 24px);
}
.timeline .timeline-item:nth-child(odd) {
  float: right;
  padding: 0 0 3rem 3rem;
  margin-top: 6rem;
}
.timeline .timeline-item:nth-child(odd) .timeline-badge {
  right: calc(100% - 24px);
}
.timeline .timeline-item .timeline-badge {
  position: absolute;
  width: 48px;
  height: 48px;
}
.timeline .timeline-item .badge-icon {
  display: inline-block;
  text-align: center;
  font-size: 22px;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  line-height: 48px;
}
.timeline .timeline-item .badge-img {
  display: inline-block;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
.timeline .timeline-group {
  position: relative;
  z-index: 99;
  padding: 0 0 2rem 0;
}
.timeline .timeline-line {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #dee2e6;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 767px) {
  .user-profile .header-cover {
    height: 200px;
  }

  .timeline .timeline-item {
    width: 100%;
    padding: 4rem 0 3rem !important;
  }
  .timeline .timeline-item:nth-child(odd) {
    margin-top: 1rem;
  }
  .timeline .timeline-item .timeline-badge {
    left: 0 !important;
    right: 0 !important;
    top: -16px;
    margin: auto;
  }
  .timeline .timeline-group {
    padding: 0 0 3rem;
  }
}
.auth-layout-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
}
.auth-layout-wrap .auth-content {
  max-width: 660px;
  margin: auto;
}

.auth-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.auth-logo img {
  width: 100px;
  height: 100px;
}

@media (min-width: 1024px) {
  .auth-layout-wrap .auth-content {
    min-width: 660px;
  }
}
@media (max-width: 767px) {
  .auth-layout-wrap .auth-content {
    padding: 15px;
  }

  .auth-right {
    padding: 80px 15px;
  }
}
.not-found-wrap {
  background-image: url("../../../../images/page-bg-bottom.png");
  background-position-y: bottom;
  background-size: cover;
  background-size: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  padding: 120px 0;
}
.not-found-wrap h1 {
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 1;
}
.not-found-wrap .subheading {
  font-weight: 800;
}

html {
  font-size: 16px;
}

body {
  letter-spacing: 0.3px;
  line-height: 1.6;
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html[dir=rtl], html[dir=ltr], body[dir=rtl], body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img {
  max-width: 100%;
}

a, a:focus, a:hover {
  text-decoration: none;
}

blockquote {
  border-left: 2px solid #eee;
  padding-left: 1rem;
  margin-bottom: 1rem;
  font-size: 1.01625rem;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  outline: none;
}

.o-hidden {
  overflow: hidden;
}

.separator-breadcrumb {
  margin-bottom: 2rem;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.line-height-3 {
  line-height: 3;
}

.line-height-4 {
  line-height: 4;
}

.line-height-5 {
  line-height: 5;
}

.app-inro-circle {
  text-align: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(50% - 150px);
}
.app-inro-circle .big-bubble {
  height: 280px;
  width: 280px;
  margin: 0 auto 20px;
  text-align: center;
  background: #0092b0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-inro-circle .big-bubble i {
  font-size: 108px;
  color: #ffffff;
}

.loadscreen {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(50% - 100px);
}
.loadscreen .logo {
  display: inline-block !important;
  width: 80px;
  height: 80px;
}

@media (max-width: 576px) {
  .app-inro-circle .big-bubble {
    width: 220px;
    height: 220px;
  }
}
[dir=rtl] .rtl-ps-none .ps__rail-x, [dir=rtl] .rtl-ps-none .ps__rail-y {
  display: none;
}

.endev-active {
  background-color: darkcyan;
}

.endev {
  background-color: gainsboro;
}

.__PrivateStripeElement {
  width: 300px;
}

.ng-dropdown-panel {
  background-color: lightsteelblue;
}

.ng-select .ng-select-container {
  color: #333;
}

.ng-select .ng-select-container {
  border-radius: 4px;
  min-height: 36px;
  align-items: center;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #999;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-select .ng-select-container .ng-value-container {
  display: flex;
  flex: 1;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-left: 10px;
  padding-right: 50px;
}

.error {
  color: red;
}

label span {
  color: red;
}

.flex-vertical-center, .cus-info-section, .cus-wizard-container .card, .cus-create-password .cus-create-pwd-form, .cus-login-container .cos-session-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.wizard-indicator, .cus-wizard-container form-wizard .card .nav .nav-item.enabled.active a::before, .cus-wizard-container form-wizard .card .nav .nav-item.enabled a::before, .cus-wizard-container form-wizard .card .nav .nav-item a::before {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
}

.en-wizard-container, .cus-login-container {
  background-image: url("../../../images/en-login.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.en-login-left {
  background-color: rgba(255, 255, 255, 0.9);
}

.auth-logo img {
  width: 90px;
  height: 90px;
}

.cus-login-container .cos-session-form {
  padding: 20px 100px;
}
.cus-login-container .cos-session-form.col-login {
  max-width: 620px;
}
.cus-login-container .form-group .form-control {
  background: transparent;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: calc(3.5rem + 2px);
  padding: 0.375rem 0.9rem;
}
.cus-login-container .form-group label {
  font-size: 14px;
  color: #212121;
  margin-bottom: 4px;
}
.cus-login-container .form-group .error {
  color: #d22346;
  font-size: 13px;
}
.cus-login-container .en-company-user {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
}
.cus-login-container .en-company-user .form-check-input {
  margin-top: 9px;
}
.cus-login-container .en-company-user .form-check {
  position: relative;
  border: 2px solid #f4f5fc;
  background-color: #f4f5fc;
  padding: 2px 4px;
  border-radius: 5px;
}
.cus-login-container .en-company-user .form-check p {
  padding-top: 10px;
}
.cus-login-container .en-company-user .form-check p span {
  position: absolute;
  top: 0px;
  left: 29px;
  font-size: 10px;
  font-weight: 400;
  color: #f4821f;
}

.form-group .form-control {
  background: #f4f5fc;
  border: none;
  height: calc(3rem + 2px);
  padding: 0.375rem 0.75rem;
}
.form-group label {
  font-size: 14px;
  color: #212121;
  margin-bottom: 4px;
}

.ng-select.ng-select-single .ng-select-container {
  background: #f4f5fc;
  border: none;
  height: calc(3rem + 2px);
  padding: 0.375rem 0.75rem;
}
.ng-select.ng-select-single .ng-select-container::before {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  top: 18px;
  right: 10px;
  width: 18px;
  height: 18px;
  transition-delay: 0.4ms;
  opacity: 0.5;
}

.btn {
  padding: 0.6rem 2rem;
  border-radius: 25px;
  border: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.en-country-code .iti {
  width: 100%;
}
.en-country-code .iti.separate-dial-code input {
  width: 100%;
  background: #f4f5fc;
  border: none;
  height: calc(3.5rem + 2px);
  padding: 0.375rem 0.75rem;
}
.en-country-code .iti input#country-search-box {
  padding-left: 10px;
  height: calc(2.5rem + 5px);
}
.en-country-code .iti .dropdown-menu {
  border: none;
  background-color: transparent;
  width: 430px;
}

input[type=radio] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: #0092b0;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #0092b0;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-top: 5px;
}

input[type=radio]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #0092b0;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type=radio]:checked::before {
  transform: scale(1);
}

input[type=radio]:focus {
  outline: max(2px, 0.15em) solid #0092b0;
  outline-offset: max(2px, 0.15em);
}

.form-check-label {
  padding-left: 25px;
}

.cus-wizard-container .card {
  background-color: transparent;
  padding: 20px 100px;
  box-shadow: none;
}
.cus-wizard-container .card .card-block {
  width: 100%;
  padding: 15px;
  overflow-y: initial;
  clear: both;
}
.cus-wizard-container .card .card-header {
  display: block;
  width: 100%;
  border: none;
  padding: 0 15px;
}
.cus-wizard-container .card .card-footer {
  display: block;
  width: 100%;
  text-align: right;
}
.cus-wizard-container form-wizard .card .nav {
  display: block;
}
.cus-wizard-container form-wizard .card .nav .nav-item {
  float: left;
  margin-right: 10px;
}
.cus-wizard-container form-wizard .card .nav .nav-item a {
  position: relative;
  border-bottom: none;
  height: 25px;
  display: inline-block;
  padding-left: 30px;
  font-weight: 600;
  line-height: 25px;
}
.cus-wizard-container form-wizard .card .nav .nav-item a::before {
  background-image: url(../../../images/check_mute.svg);
}
.cus-wizard-container form-wizard .card .nav .nav-item.enabled a {
  color: #50ca5e;
}
.cus-wizard-container form-wizard .card .nav .nav-item.enabled a::before {
  background-image: url(../../../images/check_enable.svg);
}
.cus-wizard-container form-wizard .card .nav .nav-item.enabled.active a {
  background: transparent;
  border-color: transparent;
  color: #000000;
}
.cus-wizard-container form-wizard .card .nav .nav-item.enabled.active a::before {
  background-image: url(../../../images/check_active.svg);
}
.cus-wizard-container form-wizard .card .card-footer .btn {
  color: #ffffff;
  background-color: #0092b0;
  background-image: none;
  border-color: #0092b0;
}
.cus-wizard-container form-wizard .card .card-footer .btn:disabled {
  background: #e5f4f7;
  color: #000000;
}
.cus-wizard-container .cus-connect-container {
  position: relative;
  background-color: #0092b0;
  padding: 20px;
  border-radius: 10px;
  min-height: 320px;
}
.cus-wizard-container .cus-connect-container .avatar {
  background-color: #ffffff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 10px;
}
.cus-wizard-container .cus-connect-container .cus-radio {
  position: absolute;
  top: 10px;
  right: 10px;
}
.cus-wizard-container .cus-connect-container .cus-radio input[type=radio] {
  color: #ffffff;
  border: 0.15em solid #ffffff;
}
.cus-wizard-container .cus-connect-container .cus-radio input[type=radio]::before {
  box-shadow: inset 1em 1em #ffffff;
}
.cus-wizard-container .cus-connect-container .cus-radio input[type=radio]:focus {
  outline: max(2px, 0.15em) solid #ffffff;
}
.cus-wizard-container .ng-dropdown-panel {
  background-color: #f4f5fc;
  position: absolute;
  padding: 10px 0px 10px 10px;
  border-radius: 4px;
  margin-top: 1px;
}
.cus-wizard-container .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 100px;
}
.cus-wizard-container .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding-bottom: 6px;
  padding-top: 6px;
  border-bottom: 1px dashed #ededed;
}
.cus-wizard-container .en-payment-control form {
  position: relative;
}
.cus-wizard-container .en-payment-control form .pay-now-btn {
  position: absolute;
  right: 0;
  bottom: -70px;
}

.ng-dropdown-panel {
  background-color: #f4f5fc;
  position: absolute;
  padding: 10px 0px 10px 10px;
  border-radius: 4px;
  margin-top: 1px;
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 100px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding-bottom: 6px;
  padding-top: 6px;
  border-bottom: 1px dashed #ededed;
}

.cus-wizard-container.non-session-wizard .card {
  display: block;
  justify-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  min-height: auto;
  padding: 20px 15px;
}

.cus-payment-card {
  position: relative;
  background-image: url("../../../images/credit-card.png");
  background-repeat: no-repeat;
  width: 335px;
  height: 215px;
  border-radius: 10px;
  padding: 22px 12px;
}
.cus-payment-card h5 {
  color: #ffffff;
}
.cus-payment-card label {
  color: #ffffff;
  font-size: 18px;
}
.cus-payment-card .cus-card-info {
  position: absolute;
  bottom: 50px;
  left: 10px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c8c8c8;
}

::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

@media only screen and (max-width: 1366px) {
  .cus-wizard-container .card {
    padding: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .cus-wizard-container .card {
    padding: 20px 15px;
  }

  .cus-login-container .cos-session-form {
    padding: 20px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .cus-wizard-container .card {
    padding: 20px 0;
  }
}
.en--p-15 {
  padding: 15px;
}

.cus-color-primary {
  color: #0092b0;
}

.cus-color-secondry {
  color: #f4821f;
}

.cus-white-color {
  color: #ffffff;
}

.bg-pre-98 {
  background-color: rgba(0, 146, 176, 0.94);
}

.btn-know-more {
  background-color: #f4821f;
  color: #ffffff;
  transition-delay: 0.3ms;
}
.btn-know-more:hover {
  background-color: #ffffff;
  color: #0092b0;
}

.cus-black-color {
  color: #000000;
}

.en-plr0-tb-15 {
  padding: 15px 0;
}

.en-block-100 {
  display: block;
  width: 100%;
  clear: both;
}

.row-small-gutter {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row-small-gutter > * {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.InputContainer .InputElement:focus {
  color: #ffffff !important;
}

.layout-sidebar-large .sidebar-left-secondary,
.layout-sidebar-large .sidebar-left {
  background: #001114;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item {
  color: #ffffff;
  border-bottom: 1px solid #000000;
  border-top: 1px solid #002228;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:hover {
  background-color: #0092b0;
  transition-delay: 0.3ms;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active {
  color: #ffffff;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active .triangle {
  border-color: transparent transparent #0092b0 transparent;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:first-child {
  border-top: none;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:last-child::before {
  content: "";
  border-bottom: 1px solid #002228;
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  bottom: -2px;
}

.layout-sidebar-large .sidebar-left-secondary.open {
  background-color: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fbfbfb;
}

.modal-content {
  border: none;
}

.en-remove-span {
  position: inherit !important;
  background-color: transparent !important;
  color: #000000 !important;
  font-size: 15px !important;
  border-radius: none !important;
}

.btn.btn-outline-light.btn-svg.active,
.btn.btn-outline-light.btn-svg:hover {
  background: #0092b0;
  border-color: #0092b0;
  color: #ffffff;
}

.cus-table-container .table {
  margin-bottom: 0;
}
.cus-table-container .table .thead-light th {
  color: #ffffff;
  background-color: #0092b0;
  border: none;
}
.cus-table-container .empty-item {
  padding: 60px 0;
}

.empty-item-add {
  height: 40vh;
  align-items: center;
  justify-content: center;
}

.en-upload-wrapper .en-upload-img {
  position: relative;
  width: 80px;
  height: 80px;
  display: inline-block;
  border: 2px dashed #cccccc;
  margin-right: 5px;
  overflow: hidden;
  padding: 6px;
  margin-top: 20px;
  border-radius: 15px;
}
.en-upload-wrapper .en-upload-img img {
  width: 80px;
  height: auto;
  object-fit: cover;
}
.en-upload-wrapper .en-upload-img .en-close-product {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  cursor: pointer;
}
.en-upload-wrapper .custom-file-input {
  height: calc(3.5rem + 2px);
}
.en-upload-wrapper .input-group-text {
  border: 1px solid #ced4da;
  background-color: #ffffff;
  border-radius: 5px;
  height: 50px;
  color: #a3a3a3;
}
.en-upload-wrapper .input-group > .custom-file:not(:first-child) .custom-file-label {
  padding: 15px;
  height: 50px;
}
.en-upload-wrapper .custom-file-label {
  top: 0;
  left: 0;
  border-radius: 5px;
}
.en-upload-wrapper .custom-file-label::after {
  height: 50px;
  padding: 15px;
  color: #f4f5fc;
  content: "Browse";
  background-color: transparent;
}

.top-right-btn {
  background-color: #0092b0;
  height: 32px;
  color: #ffffff;
  padding: 5px 15px;
  border-radius: 25px;
  transition-delay: 0.3ms;
}
.top-right-btn:hover {
  background-color: #f4821f;
  color: #ffffff;
}

.action-btn-exten, .en-grid-list-container .card .en-edit-btn, .cus-action ul li {
  display: inline-block;
  cursor: pointer;
  background-color: #eaeaee;
  width: 32px;
  height: 32px;
  margin-left: 5px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50%;
  transition: 0.3ms;
}

.cus-action ul {
  padding: 10px 5px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
}
.cus-action ul li.cus-view {
  background-color: rgba(255, 181, 43, 0.2);
}
.cus-action ul li.cus-view svg {
  fill: #ffb52b;
}
.cus-action ul li.cus-view:hover {
  background-color: #ffb52b;
}
.cus-action ul li.cus-view:hover svg {
  fill: #ffffff;
}
.cus-action ul li.cus-edit {
  background-color: rgba(0, 196, 85, 0.2);
}
.cus-action ul li.cus-edit svg {
  fill: #00c455;
}
.cus-action ul li.cus-edit:hover {
  background-color: #00c455;
}
.cus-action ul li.cus-edit:hover svg {
  fill: #ffffff;
}
.cus-action ul li.cus-delete {
  background-color: rgba(241, 52, 38, 0.2);
}
.cus-action ul li.cus-delete svg {
  fill: #f13426;
}
.cus-action ul li.cus-delete:hover {
  background-color: #f13426;
}
.cus-action ul li.cus-delete:hover svg {
  fill: #ffffff;
}

.breadcrumb {
  display: block;
  flex-wrap: inherit;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}
.breadcrumb .en-breadcrumb-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.breadcrumb .en-breadcrumb-inner h1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  vertical-align: middle;
  font-weight: 700;
  line-height: 0;
}
.breadcrumb .en-breadcrumb-inner ul {
  display: inline-block;
  vertical-align: middle;
}
.breadcrumb .en-breadcrumb-inner ul li {
  float: left;
  padding: 0;
  margin-right: 10px;
}
.breadcrumb .en-breadcrumb-inner ul li::after {
  display: none;
}
.breadcrumb .en-breadcrumb-inner ul li::before {
  display: none;
}
.breadcrumb .en-breadcrumb-inner ul li a {
  padding-left: 15px;
  position: relative;
  font-weight: 500;
  color: #0092b0;
  transition: 0.3ms;
}
.breadcrumb .en-breadcrumb-inner ul li a:hover {
  color: #f4821f;
}
.breadcrumb .en-breadcrumb-inner ul li a::before {
  position: absolute;
  content: "";
  background-color: #f4821f;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 0;
  top: 8px;
}

.cus-order-status {
  text-align: right;
  padding-top: 0px;
}
.cus-order-status ul {
  list-style: none;
  margin: 0;
}
.cus-order-status ul li {
  position: relative;
  display: inline-block;
  padding: 0;
  transition-delay: 0.3ms;
}
.cus-order-status ul li::after {
  display: none;
}
.cus-order-status ul li.active a, .cus-order-status ul li:hover a {
  color: #0092b0;
  border-color: #0092b0;
}
.cus-order-status ul li.active svg, .cus-order-status ul li:hover svg {
  fill: #0092b0;
}
.cus-order-status ul li.active span, .cus-order-status ul li:hover span {
  background-color: #0092b0;
  color: #ffffff;
}
.cus-order-status ul li span {
  position: absolute;
  background-color: rgba(0, 146, 176, 0.2);
  color: #000000;
  display: inline-block;
  padding: 2px 5px 2px;
  right: 0;
  top: -18px;
  font-size: 12px;
  border-radius: 25px;
}
.cus-order-status ul li a {
  font-size: 14px;
  border-bottom: 2px solid transparent;
  margin-left: 25px;
  padding-bottom: 19px;
  font-weight: 600;
  color: #000000;
}
.cus-order-status ul li a svg {
  fill: #999999;
  vertical-align: bottom;
}
.cus-order-status ul li.en-status-pending span {
  background-color: rgba(244, 130, 31, 0.2);
}
.cus-order-status ul li.en-status-pending.active a, .cus-order-status ul li.en-status-pending:hover a {
  color: #f4821f;
  border-color: #f4821f;
}
.cus-order-status ul li.en-status-pending.active svg, .cus-order-status ul li.en-status-pending:hover svg {
  fill: #f4821f;
}
.cus-order-status ul li.en-status-pending.active span, .cus-order-status ul li.en-status-pending:hover span {
  background-color: #f4821f;
  color: #ffffff;
}
.cus-order-status ul li.en-status-processing span {
  background-color: rgba(255, 181, 43, 0.2);
}
.cus-order-status ul li.en-status-processing.active a, .cus-order-status ul li.en-status-processing:hover a {
  color: #ffb52b;
  border-color: #ffb52b;
}
.cus-order-status ul li.en-status-processing.active svg, .cus-order-status ul li.en-status-processing:hover svg {
  fill: #ffb52b;
}
.cus-order-status ul li.en-status-processing.active span, .cus-order-status ul li.en-status-processing:hover span {
  background-color: #ffb52b;
  color: #ffffff;
}
.cus-order-status ul li.en-status-ready span {
  background-color: rgba(0, 146, 176, 0.2);
}
.cus-order-status ul li.en-status-ready.active a, .cus-order-status ul li.en-status-ready:hover a {
  color: #0092b0;
  border-color: #0092b0;
}
.cus-order-status ul li.en-status-ready.active svg, .cus-order-status ul li.en-status-ready:hover svg {
  fill: #0092b0;
}
.cus-order-status ul li.en-status-ready.active span, .cus-order-status ul li.en-status-ready:hover span {
  background-color: #0092b0;
  color: #ffffff;
}
.cus-order-status ul li.en-status-completed span {
  background-color: rgba(0, 196, 85, 0.2);
}
.cus-order-status ul li.en-status-completed.active a, .cus-order-status ul li.en-status-completed:hover a {
  color: #00c455;
  border-color: #00c455;
}
.cus-order-status ul li.en-status-completed.active svg, .cus-order-status ul li.en-status-completed:hover svg {
  fill: #00c455;
}
.cus-order-status ul li.en-status-completed.active span, .cus-order-status ul li.en-status-completed:hover span {
  background-color: #00c455;
  color: #ffffff;
}
.cus-order-status ul li.en-status-cancelled span {
  background-color: rgba(241, 52, 38, 0.2);
}
.cus-order-status ul li.en-status-cancelled.active a, .cus-order-status ul li.en-status-cancelled:hover a {
  color: #f13426;
  border-color: #f13426;
}
.cus-order-status ul li.en-status-cancelled.active svg, .cus-order-status ul li.en-status-cancelled:hover svg {
  fill: #f13426;
}
.cus-order-status ul li.en-status-cancelled.active span, .cus-order-status ul li.en-status-cancelled:hover span {
  background-color: #f13426;
  color: #ffffff;
}
.cus-order-status ul li.en-status-receipts span {
  background-color: rgba(68, 68, 68, 0.2);
}
.cus-order-status ul li.en-status-receipts.active a, .cus-order-status ul li.en-status-receipts:hover a {
  color: #444444;
  border-color: #444444;
}
.cus-order-status ul li.en-status-receipts.active svg, .cus-order-status ul li.en-status-receipts:hover svg {
  fill: #444444;
}
.cus-order-status ul li.en-status-receipts.active span, .cus-order-status ul li.en-status-receipts:hover span {
  background-color: #444444;
  color: #ffffff;
}
.cus-order-status.view-order-container ul li {
  margin-left: 15px;
  vertical-align: middle;
}
.cus-order-status.view-order-container ul li:hover svg {
  fill: #0f343b;
}
.cus-order-status.view-order-container ul li h6 {
  margin-bottom: 0;
}
.cus-order-status.view-order-container ul li h6 a {
  color: #0f343b;
  margin-left: 0;
  border: none;
}
.cus-order-status.view-order-container ul li h6 a:hover {
  color: #0092b0;
}

.bage-btn {
  background-color: rgba(68, 68, 68, 0.2);
  color: #444444;
  border: none;
  padding: 5px 10px;
  border-radius: 25px;
}
.bage-btn svg {
  fill: #444444;
}
.bage-btn:hover {
  background-color: #444444;
  color: #444444;
}
.bage-btn:hover svg {
  fill: #444444;
}
.bage-btn.bage-add {
  background-color: rgba(0, 196, 85, 0.2);
  color: #00c455;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}
.bage-btn.bage-add svg {
  fill: #00c455;
}
.bage-btn.bage-add:hover, .bage-btn.bage-add:focus {
  background-color: #00c455;
  color: #ffffff;
}
.bage-btn.bage-add:hover svg, .bage-btn.bage-add:focus svg {
  fill: #ffffff;
}
.bage-btn.bage-remove {
  background-color: rgba(241, 52, 38, 0.2);
  color: #f13426;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}
.bage-btn.bage-remove svg {
  fill: #f13426;
}
.bage-btn.bage-remove:hover, .bage-btn.bage-remove:focus {
  background-color: #f13426;
  color: #ffffff;
}
.bage-btn.bage-remove:hover svg, .bage-btn.bage-remove:focus svg {
  fill: #ffffff;
}

.en-grid .btn {
  padding: 0.3rem 1rem;
}
.en-grid .btn-outline-light {
  color: #212121;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-order-group {
  background-color: #0092b0;
  color: #ffffff;
  padding: 0.4rem 1rem;
  transition-delay: 0.4ms;
}
.btn-order-group:hover {
  background-color: #f4821f;
}

.btn-order-group-sub {
  background-color: #f4821f;
  color: #ffffff;
  padding: 0.4rem 1rem;
  transition-delay: 0.4ms;
}
.btn-order-group-sub:hover {
  background-color: #0092b0;
}

.en-grid-list-container .card {
  position: relative;
}
.en-grid-list-container .card .badge {
  background-color: #f4821f;
  color: #ffffff;
}
.en-grid-list-container .card .en-edit-btn {
  background-color: #00c455;
  padding: 0;
  width: 36px;
  height: 36px;
}
.en-grid-list-container .card .en-edit-btn svg {
  fill: #ffffff;
}
.en-grid-list-container .card .en-edit-btn:hover {
  background-color: #f4821f;
}
.en-grid-list-container .card .en-edit-btn:hover svg {
  fill: #ffffff;
}
.en-grid-list-container.list-horizontal .list-item .list-thumb img {
  height: 80px;
  width: 100px;
}
.en-grid-list-container.list-horizontal .list-item .item-title {
  width: 230px;
}

.account-settings-wrapper .account-setting-header {
  background-color: #0092b0;
  border-radius: 10px 10px 0 0;
  height: 150px;
  overflow: hidden;
}
.account-settings-wrapper .account-setting-header img {
  background-position: left top;
  background-size: cover;
}
.account-settings-wrapper .en-avatar-section {
  background-color: #f8f9fa;
}
.account-settings-wrapper .en-avatar-section .avatar {
  position: relative;
  border: 3px solid #ffffff;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 50%;
  margin-top: -70px;
  margin-left: 15px;
}
.account-settings-wrapper .en-avatar-section .avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.account-settings-wrapper .en-avatar-section .avatar .en-avatar-edit {
  position: absolute;
  right: 0;
  z-index: 1;
  top: -15px;
}
.account-settings-wrapper .en-avatar-section .avatar .en-avatar-edit input {
  display: none;
}
.account-settings-wrapper .en-avatar-section .avatar .en-avatar-edit input + label {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  text-align: center;
  padding-top: 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.account-settings-wrapper .en-avatar-section .avatar .en-avatar-edit input + label svg {
  fill: #0092b0;
}
.account-settings-wrapper .en-avatar-section .avatar .en-avatar-edit input + label:hover {
  background: #f4821f;
}
.account-settings-wrapper .en-avatar-section .avatar .en-avatar-edit input + label:hover svg {
  fill: #ffffff;
}
.account-settings-wrapper .en-profile-details {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 0 0 10px 10px;
}
.account-settings-wrapper .en-profile-details .en-cus-name {
  padding-left: 130px;
  padding-bottom: 40px;
  margin-top: -70px;
}
.account-settings-wrapper .en-profile-details .en-cus-name h4 {
  font-size: 1.5rem;
  font-weight: 700;
}
.account-settings-wrapper .en-profile-details .en-cus-name h6 {
  color: #0092b0;
}
.account-settings-wrapper .en-profile-details .en-cus-name h6 svg {
  fill: #0092b0;
}
.account-settings-wrapper .en-profile-details .en-address-details {
  background-color: #f4821f;
  padding: 30px;
  border-radius: 10px;
}
.account-settings-wrapper .en-profile-details .en-address-details small,
.account-settings-wrapper .en-profile-details .en-address-details h6 {
  color: #ffffff;
}
.account-settings-wrapper .en-profile-details .en-address-details svg {
  fill: #ffffff;
}
.account-settings-wrapper .en-profile-details .en-media-type {
  overflow-y: scroll;
  max-height: 150px;
}

.en-customer-title {
  background-color: #0092b0;
  padding: 15px 15px;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
}

.en-customer-details ul {
  list-style: none;
  padding: 0;
}
.en-customer-details ul li {
  position: relative;
  padding: 15px 10px 15px 65px;
  border-bottom: 1px dashed #ededed;
}
.en-customer-details ul li .en-initial {
  position: absolute;
  background-color: #0092b0;
  color: #ffffff;
  width: 42px;
  height: 42px;
  text-align: center;
  padding-top: 10px;
  border-radius: 50%;
  left: 10px;
  top: 8px;
}
.en-customer-details ul li .en-name-first-last {
  display: inline-block;
}
.en-customer-details ul li .en-badge {
  float: right;
  background-color: #f4821f;
  color: #ffffff;
  padding: 2px 15px;
  border-radius: 25px;
  font-size: 14px;
}

.en-product-group {
  padding: 15px;
  border: 1px dashed #ebebeb;
  border-radius: 10px;
  margin-bottom: 10px;
}

.en-add-new-item {
  padding: 10px 12px;
  background-color: #f8f9fa;
  border-radius: 25px;
}
.en-add-new-item small {
  color: #00c455;
  font-weight: 200;
}

.en-social-media {
  position: relative;
}
.en-social-media .en-media-type .en-social-media-inner {
  display: inline-block;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px 30px 5px 5px;
  border-radius: 25px;
  margin-right: 5px;
  margin-bottom: 2px;
  cursor: pointer;
  transition-delay: 0.4ms;
}
.en-social-media .en-media-type .en-social-media-inner:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.en-social-media .en-media-type .en-social-media-inner .en-social-media-img img {
  width: 30px;
  height: 30px;
}
.en-social-media .en-media-type .en-social-media-inner .en-social-media-img span {
  color: #ffffff;
  font-weight: 600;
  margin-left: 5px;
}
.en-social-media .en-media-type .en-social-media-inner .en-close-product {
  position: absolute;
  right: 8px;
  top: 6px;
  cursor: pointer;
}
.en-social-media .en-media-type .en-social-media-inner .en-close-product svg {
  fill: #ffffff;
}
.en-social-media .en-media-type .en-social-media-inner .en-close-product:hover svg {
  fill: #f13426;
}
.en-social-media .social-btn {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffffff;
  color: #000000;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 600;
}
.en-social-media .social-btn svg {
  fill: #000000 !important;
}
.en-social-media .social-btn:hover {
  background-color: #0092b0;
  color: #ffffff;
}
.en-social-media .social-btn:hover svg {
  fill: #ffffff !important;
}

textarea {
  background: #f4f5fc;
  border: none;
  padding: 0.375rem 0.75rem;
  width: 100%;
}

.modal-content {
  border: none;
  padding: 30px;
  border-radius: 15px;
}
.modal-content img {
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 15px;
}

dp-date-picker {
  width: 100%;
}
dp-date-picker.dp-material .dp-picker-input {
  box-sizing: border-box !important;
  width: 100% !important;
  font-size: 15px !important;
  color: black;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #E6E7E9;
  border-radius: 8px;
  background-color: #fff;
  background: transparent;
  height: calc(2.4em + 0.75rem + 2px) !important;
  padding: 0.5rem 1rem;
}
dp-date-picker .dp-material.dp-popup {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-left: none;
  border-right: none;
  border-bottom: none;
  z-index: 9999;
  white-space: nowrap;
}
dp-date-picker dp-calendar-nav.dp-material .dp-calendar-nav-container {
  height: 40px;
  border: none;
}
dp-date-picker dp-day-calendar.dp-material .dp-calendar-wrapper {
  border: none;
  padding: 15px;
}
dp-date-picker dp-day-calendar.dp-material .dp-calendar-weekday {
  color: black;
  border: none;
  font-size: 11px;
}
dp-date-picker dp-day-calendar.dp-material .dp-current-day {
  border: none;
  background-color: #29BF70;
}
dp-date-picker dp-day-calendar .dp-calendar-day {
  font-size: 12px;
}
dp-date-picker dp-day-time-calendar.dp-material dp-time-select {
  border: none;
  border-top: 1px dashed #dcdcdc;
}
dp-date-picker dp-month-calendar.dp-material .dp-calendar-month {
  font-size: 12px;
  width: 45px;
  height: 45px;
  padding: 15px 0;
}
dp-date-picker dp-month-calendar.dp-material .dp-current-month {
  border: none !important;
  background-color: #29BF70;
}

@media only screen and (max-width: 767px) {
  html,
body {
    overflow-x: hidden;
    clear: both;
  }

  .en-sm-none {
    display: none;
  }

  .en-login-left {
    margin-top: 40px;
  }

  .en-lock-out {
    right: 30px;
    top: -25px;
  }
}